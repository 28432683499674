<template>
  <main class="JOBSCOPE">
    <MainHeader />
    <section class="top">
      <div class="top_flex-upper">
        <div class="top_content">
          <h1>
            日本の人事に最適化した<br
              v-if="spFlg"
            />ジョブ型人事クラウドサービス<br />
            人的資本の価値を最大化する<br
              v-if="spFlg"
            />リスキリングとジョブ型雇用
          </h1>
          <p class="text" v-if="spFlg">
            経営戦略と人事戦略の連動による<br />
            企業価値最大化のための仕組み作り
          </p>
          <div class="link_to_movie" v-if="spFlg">
            <a
              href="https://offers.jobscope.ai/job-scope-b-movie04"
              target="_brank"
              >動画でJOB Scopeの特徴を確認する</a
            >
          </div>
        </div>
      </div>
      <div class="top_flex-lower">
        <div class="top_flex-left">
          <p class="text" v-if="!spFlg">
            経営戦略と人事戦略の連動による<br />
            企業価値最大化のための仕組み作り
          </p>
          <div class="link_to_movie" v-if="!spFlg">
            <a
              href="https://offers.jobscope.ai/job-scope-b-movie04"
              target="_brank"
              >動画でJOB Scopeの特徴を確認する</a
            >
          </div>
          <div class="annotations">
            <div class="icd">
              <a
                class="icd_img"
                href="https://www.icda.or.jp/business-activity/service/"
                target="_blank"
              >
                <img
                  src="../assets/img/common/icd.webp"
                  alt="iCD協会認定システム"
                  aria-label="iCD協会認定システム"
                />
              </a>
              <div class="icd_text">
                <p class="icd_head">iCD準拠システム</p>
                <p class="icd_exp">
                  JOB
                  Scopeは、独立行政法人情報処理推進機構(IPA)が公開した「iコンピテンシ
                  ディクショナリ」に準拠したシステムです。
                </p>
              </div>
            </div>
            <div class="it">
              <a class="it_img" href="javascript:void(0)">
                <img src="../assets/img/common/it.webp" alt="" />
              </a>
              <div class="it_text">
                <p class="it_head">IT導入支援事業者 認定</p>
                <p class="it_exp">
                  経済産業省が実施する「IT導入補助金2022」において、IT導入支援事業者に認定されました。補助金対象企業様の申請により、30万円～150万円（補助率1/2以内）の補助金が支給されます。<a
                    href="https://marketing.jobscope.ai/it-introduction-subsidies"
                    target="_brank"
                    class="it-procedure"
                    >IT導入補助金申請手順</a
                  >
                </p>
              </div>
            </div>
            <!-- <p class="annotation">
              ※1：日本初：人事系クラウドサービスにおいて。<span>
                (2022 年 4月現在、株式会社CSI調べ)</span
              >
            </p> -->
            <div class="links">
              <div class="link_to_ai">
                <a
                  href="https://marketing.jobscope.ai/salary-analysis"
                  target="_brank"
                  >AI給与分析<img
                    src="../assets/img/common/arrow_right_big.svg"
                    class="btn_arrow"
                    alt="JOB Scope"
                /></a>
              </div>
              <div class="link_to_icd">
                <a
                  href="https://marketing.jobscope.ai/icd-reference-service"
                  target="_brank"
                  >iCDデータ分析<img
                    src="../assets/img/common/arrow_right_big.svg"
                    class="btn_arrow"
                    alt="JOB Scope"
                /></a>
              </div>
            </div>
          </div>
          <div class="sns" v-if="spFlg">
            <a
              href="https://note.com/jobscope/"
              class="sns_item"
              target="_blank"
            >
              <img src="../assets/img/common/note.png" alt="note" />
              <p>note</p>
            </a>
            <a
              href="https://www.facebook.com/JOBScope.HR"
              class="sns_item"
              target="_blank"
            >
              <img src="../assets/img/common/facebook.png" alt="facebook" />
              <p>Facebook</p>
            </a>
            <a
              href="https://twitter.com/ScopeJob"
              class="sns_item"
              target="_blank"
            >
              <img src="../assets/img/common/twitter.png" alt="twitter" />
              <p>Twitter</p>
            </a>
          </div>
          <div class="sns blogs" v-if="spFlg">
            <a
              href="https://marketing.jobscope.ai/organization-transformation"
              class="sns_item"
              target="_blank"
            >
              <picture>
                <source
                  srcset="
                ../assets/img/sp/article_bg.png   1x,
                ../assets/img/sp/article_bg@2x.png 2x
                ../assets/img/sp/article_bg@2x.png 3x
              "
                />
                <img src="../assets/img/sp/article_bg.png" loading="lazy" alt />
              </picture>
              <p>経営・人事に<br />関する記事</p>
            </a>
            <a
              href="https://blog.jobscope.ai/"
              class="sns_item"
              target="_blank"
            >
              <picture>
                <source
                  srcset="
                ../assets/img/sp/blog_bg.png   1x,
                ../assets/img/sp/blog_bg@2x.png 2x
                ../assets/img/sp/blog_bg@2x.png 3x
              "
                />
                <img src="../assets/img/sp/blog_bg.png" loading="lazy" alt />
              </picture>
              <p>ブログ</p>
            </a>
          </div>
          <div class="action_box" v-if="spFlg">
            <v-btn
              class="action contact"
              depressed
              link
              color="#F97C40"
              href="https://offers.jobscope.ai/contact"
              target="_blank"
              >デモ/お問い合わせ</v-btn
            >
            <!-- <v-btn
              class="action apply"
              depressed
              link
              color="#F97C40"
              href="https://jobscope.ai/feature/#apply"
              target="_blank"
              >お申し込み</v-btn
            > -->
          </div>
        </div>
        <div class="top_flex-right">
          <div class="sns" v-if="!spFlg">
            <a
              href="https://marketing.jobscope.ai/organization-transformation"
              class="sns_item"
              target="_blank"
            >
              <picture>
                <source
                  media="(min-width: 1024px)"
                  srcset="
                ../assets/img/sp/article_bg.png   1x,
                ../assets/img/sp/article_bg@2x.png 2x
                ../assets/img/sp/article_bg@2x.png 3x
              "
                />
                <img src="../assets/img/sp/article_bg.png" loading="lazy" alt />
              </picture>
              <p>コラム</p>
            </a>
            <a
              href="https://blog.jobscope.ai/"
              class="sns_item"
              target="_blank"
            >
              <picture>
                <source
                  srcset="
                ../assets/img/sp/blog_bg.png   1x,
                ../assets/img/sp/blog_bg@2x.png 2x
                ../assets/img/sp/blog_bg@2x.png 3x
              "
                />
                <img src="../assets/img/sp/blog_bg.png" loading="lazy" alt />
              </picture>
              <p>ブログ</p>
            </a>
            <a
              href="https://note.com/jobscope/"
              class="sns_item"
              target="_blank"
            >
              <img src="../assets/img/common/note.png" alt="note" />
              <p>note</p>
            </a>
            <a
              href="https://www.facebook.com/JOBScope.HR"
              class="sns_item"
              target="_blank"
            >
              <img src="../assets/img/common/facebook.png" alt="facebook" />
              <p>Facebook</p>
            </a>
            <a
              href="https://twitter.com/ScopeJob"
              class="sns_item"
              target="_blank"
            >
              <img src="../assets/img/common/twitter.png" alt="twitter" />
              <p>Twitter</p>
            </a>
          </div>

          <div class="promotion">
            <div class="promotion_inner">
              <PromotionTabs />
            </div>
          </div>

          <div class="news">
            <div class="news_title">
              <h2>News</h2>
              <v-btn
                class="news_btn"
                depressed
                color="#99bb55"
                @click="show_modal(1)"
                >もっとみる<img
                  src="../assets/img/common/arrow_right.svg"
                  class="news_btn_arrow"
                  alt="JOB Scope"
              /></v-btn>
            </div>
            <div class="news_flex">
              <div class="news_data" @click="show_modal(1)">
                <div class="date"><p>2022/12/15</p></div>
                <div class="detail">
                  <p>「JOB Scope」の紹介サイトをリニューアルしました。</p>
                </div>
              </div>
              <div class="news_data" @click="show_modal(1)">
                <div class="date"><p>2022/11/15</p></div>
                <div class="detail">
                  <p>
                    AIによる求人情報・給与分析情報の無料公開を開始しました。
                  </p>
                </div>
              </div>
              <div class="news_data" @click="show_modal(1)">
                <div class="date"><p>2022/11/01</p></div>
                <div class="detail">
                  <p>
                    スキルを可視化するAIリスキリングの無償提供を開始しました。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="overview">
      <div class="content_box">
        <picture class="separate_top">
          <img src="../assets/img/pc/top_separate.svg" loading="lazy" alt />
        </picture>
        <div class="overview_img" v-if="!spFlg">
          <picture>
            <img
              src="../assets/img/pc/overview_people.png"
              loading="lazy"
              alt
            />
          </picture>
        </div>
        <div class="overview_content">
          <h2>JOB Scopeとは</h2>
          <p class="text_1">
            JOB
            Scopeは、経営・事業戦略に基づく組織と人事制度設計を職務を軸としてジョブ型人事制度を忠実に実現します。
            従業員と部門目標に基づく職務と評価の仕組みを簡単に実現するだけでなく、職務と従業員のマッチングとスキルの見える化で成長支援を行います。さらに、業種横断的な人的資本・職務待遇分析で競合企業との比較分析が可能です。
          </p>
          <div class="img">
            <figure>
              <img src="../assets/img/common/icon_1.svg" alt />
              <figcaption>ジョブ型<br />人事評価</figcaption>
            </figure>
            <figure>
              <img src="../assets/img/common/icon_2.svg" alt />
              <figcaption>キャリア<br />マネジメント</figcaption>
            </figure>
            <figure>
              <img src="../assets/img/common/icon_3.svg" alt />
              <figcaption>部門変革<br />課題と解決<br /></figcaption>
            </figure>
            <figure>
              <img src="../assets/img/common/icon_4.svg" alt />
              <figcaption>
                AIリスキリング<br />分析<span class="reference_mark">※2</span>
              </figcaption>
            </figure>
            <figure>
              <img src="../assets/img/common/icon_5.svg" alt />
              <figcaption>AI職務魅力度<br />分析<span>※3</span></figcaption>
            </figure>
          </div>
          <ul>
            <li>DX時代に求められる<br v-if="!spFlg" />デジタル人財育成</li>
            <li>DX時代に適合する<br v-if="!spFlg" />組織変革</li>
            <li>新しい働き方に<br v-if="!spFlg" />対応する人財管理</li>
          </ul>
          <p class="annotation">
            ※2：AIリスキリング<br />
            従業員に対して新しいスキル・技術を身に付けさせることで、新たな価値、サービスの創出や生産性の向上、ひいては従業員の市場価値の向上につなげることを指します。背景には、社会のデジタルトランスフォーメーションにより、サービスや業務のデジタル化があります。JOB
            Scopeを通じて、人ではなく職務を通じた人事制度に変革し、加えて職務の明確化と他社比較による職務の魅力度アップ、さらに従業員のリスキリング。これらのキャリアマネジメントを通じて企業をDX環境に適応させるだけでなく、競争力強化を図ります。
            <br />「AIリスキリング」は、デフィデ株式会社の登録商標です。
          </p>
          <p class="annotation">
            ※3：「AI職務魅力度分析」は、デフィデ株式会社の登録商標です。
          </p>
        </div>
      </div>
      <picture class="separate_bottom" v-if="!spFlg">
        <source srcset="../assets/img/pc/overview_separate.svg" />
        <img src="../assets/img/pc/overview_separate.svg" loading="lazy" alt />
      </picture>
    </section>

    <div class="overview_img" v-if="spFlg">
      <div class="separate_top">
        <picture>
          <source srcset="../assets/img/sp/overview_img_top.svg" />
          <img src="../assets/img/sp/overview_img_top.svg" loading="lazy" alt />
        </picture>
      </div>
      <picture class="main_img">
        <source
          srcset="
            ../assets/img/sp/overview.png    1x,
            ../assets/img/sp/overview@2x.png 2x
          "
        />
        <img src="../assets/img/sp/overview.png" loading="lazy" alt />
      </picture>
      <div class="separate_bottom">
        <picture>
          <source srcset="../assets/img/sp/overview_img_bottom.svg" />
          <img
            src="../assets/img/sp/overview_img_bottom.svg"
            loading="lazy"
            alt
          />
        </picture>
      </div>
    </div>

    <section class="intro">
      <h2>これまでの人事制度</h2>
      <h3>
        これまでの人事制度には、<br v-if="spFlg" />以下<span class="text_green"
          >3つの課題</span
        >があります。
      </h3>
      <ol class="text_1">
        <li>
          <div><div class="num">01</div></div>
          <div>
            従来の日本型人事制度であるメンバーシップ型人事制度では、環境変化の激しい社会環境に適応できる専門性を持った競争力のある社員の育成が十分にできない点。
          </div>
        </li>
        <li>
          <div><div class="num">02</div></div>
          <div>
            新卒至上主義、長期安定雇用、メンバーシップ型組織など、かつて日本の高度経済成長を支えてきた「日本型雇用システム」は崩壊。これまでの日本型人事制度では「成長の継続」が見込めません。
          </div>
        </li>
        <li>
          <div><div class="num">03</div></div>
          <div>
            既存ビジネスのデジタル化の波で、DX人財の内製化は必然の流れとなり事業戦略を実現する職務を遂行するデジタル人財育成は喫緊の課題となった点。
          </div>
        </li>
      </ol>
      <div class="text_2">
        <p>
          以上3つの課題を<br v-if="spFlg" /><span class="text_green"
            >JOB Scope</span
          >は解決。<br />
          変革を求める企業の人財価値<br
            v-if="spFlg"
          />を最大限に引き出し、中長期<br v-if="spFlg" />的な<span
            class="text_green"
            >企業価値最大化</span
          >を<br v-if="spFlg" />実現します。
        </p>
      </div>
      <picture class="separate_bottom">
        <source srcset="../assets/img/pc/intro_bottom_separate.svg" />
        <img
          src="../assets/img/pc/intro_bottom_separate.svg"
          loading="lazy"
          alt
        />
      </picture>
    </section>
    <section class="system">
      <h2>サービス概要</h2>
      <ul v-if="!spFlg">
        <li v-for="(service, i) in services" :key="i">
          <h3>{{ service.title }}</h3>
          <div class="white_line"></div>
          <div class="flex">
            <div><img :src="service.img" alt /></div>
            <div class="text">
              <p>{{ service.text1 }}</p>
              <p>{{ service.text2 }}</p>
            </div>
          </div>
        </li>
      </ul>
      <div v-if="spFlg" class="ul">
        <hooper class="slide_box" :itemsToShow="1" ref="hooper">
          <slide class="slide" v-for="(service, i) in services" :key="i">
            <h3>{{ service.title }}</h3>
            <p>{{ service.text1 }}</p>
            <p>{{ service.text2 }}</p>
          </slide>
          <hooper-pagination slot="hooper-addons" />
        </hooper>
      </div>
    </section>
    <section class="feature">
      <picture class="separate">
        <source srcset="../assets/img/pc/feature_top_separate.svg" />
        <img
          src="../assets/img/pc/feature_top_separate.svg"
          loading="lazy"
          alt
        />
      </picture>
      <h2>JOB Scope 主な機能</h2>
      <p class="text_1">
        一人一人の成長だけでなく、<br v-if="spFlg" />競争力のある部門への<br
          v-if="spFlg"
        />変革を図ります。
      </p>
      <p class="text_2">
        従業員のキャリアマネジメント、リスキリングと呼ばれる再スキル学習の方向性や項目を人工知能が明らかにして将来のキャリア形<br />
        成を提案。また、経営戦略と人事戦略の連動による経営強化のための仕組みづくりのために、事業を経営視点での再定義、及び<br />
        それに基づく組織作りをコンサルティングサービスとして提供します。
      </p>
      <div class="feature_flex">
        <ul>
          <li v-for="(feature, i) in features" :key="i">
            <div><img :src="feature.img" alt /></div>
            <div v-html="feature.title"></div>
          </li>
        </ul>
        <picture>
          <source
            media="(max-width: 1023px)"
            srcset="../assets/img/sp/feature.jpg"
          />
          <source
            media="(min-width: 1024px)"
            srcset="
              ../assets/img/pc/feature.jpg    1x,
              ../assets/img/pc/feature@2x.jpg 2x
            "
          />
          <img src="../assets/img/pc/feature.jpg" loading="lazy" alt />
        </picture>
      </div>
      <picture class="separate">
        <source srcset="../assets/img/pc/feature_bottom_separate.svg" />
        <img
          src="../assets/img/pc/feature_bottom_separate.svg"
          loading="lazy"
          alt
        />
      </picture>
    </section>
    <section class="step">
      <h2>経営戦略・組織戦略に<br v-if="spFlg" />基づく革新企業へ</h2>
      <div class="img_area">
        <picture>
          <source
            media="(min-width: 1024px)"
            srcset="../assets/img/pc/step.svg"
          />
          <source
            media="(max-width: 1023px)"
            srcset="../assets/img/sp/step.svg"
          />
          <img src="../assets/img/pc/step.svg" loading="lazy" alt />
        </picture>
      </div>
      <picture class="separate">
        <source
          media="(max-width: 1023px)"
          srcset="../assets/img/sp/step_bottom_separate.svg"
        />
        <source
          media="(min-width: 1024px)"
          srcset="../assets/img/pc/step_bottom_separate.svg"
        />
        <img
          src="../assets/img/pc/step_bottom_separate.svg"
          loading="lazy"
          alt
        />
      </picture>
    </section>
    <section class="support_system">
      <h2>サポート体制</h2>
      <div class="support_system_flex">
        <div>
          <h3>
            クラウドサービスと<br />コンサルティングサービスによる<br />個々の企業に応じた成長支援
          </h3>
          <p>
            JOB Scope
            においては、人事制度変革クラウドサービスの提供のみに留まることなく、本サービスを導入される個々の企業の特性に応じた人事制度設計及び導入・運用支援を、300社以上の人事制度改革支援実績を誇る人事コンサルタントや全国の社会保険労務士によるコンサルティングサービスと一体運用することで、従業員及び部門の成長、しいては競争力のある企業への成長を支援いたします。
          </p>
        </div>
        <div class="img">
          <picture>
            <source
              media="(min-width: 1024px)"
              srcset="
                ../assets/img/pc/support_system.png    1x,
                ../assets/img/pc/support_system@2x.png 2x,
                ../assets/img/pc/support_system@3x.png 3x
              "
            />
            <source
              media="(max-width: 1023px)"
              srcset="
                ../assets/img/sp/support_system.png    1x,
                ../assets/img/sp/support_system@2x.png 2x,
                ../assets/img/sp/support_system@3x.png 3x
              "
            />
            <img src="../assets/img/pc/support_system.png" loading="lazy" alt />
          </picture>
        </div>
      </div>
      <picture class="separate" v-if="!spFlg">
        <source srcset="../assets/img/pc/support_bottom_separate.svg" />
        <img
          src="../assets/img/pc/support_bottom_separate.svg"
          loading="lazy"
          alt
        />
      </picture>
    </section>
    <section class="design_concept">
      <picture class="separate" v-if="spFlg">
        <source srcset="../assets/img/sp/support_bottom_separate.svg" />
        <img
          src="../assets/img/sp/support_bottom_separate.svg"
          loading="lazy"
          alt
        />
      </picture>
      <h2>
        JOB Scopeにおける<br class="pc_hide" />ジョブ型人事制度の<br
          class="pc_hide"
        />設計思想
      </h2>
      <div class="design_concept_idea">
        <p class="idea_text">
          世界標準仕様として日本にも浸透しつつあるジョブ型人事制度は、職務（Job）に基づく雇用･組織編制・配置･評価･報酬決定を行うもので、次のような構成要素から成り立っています。
        </p>
        <ul class="idea_list">
          <li>
            <div><span>1. </span><span>組織設計</span></div>
            <div>
              事業に最適な組織の区分と責任階層・職種別の職務ポジションを編制します。
            </div>
          </li>
          <li>
            <div><span>2. </span><span>職務の定義</span></div>
            <div>
              職務内容および行動基準のディクショナリーに基づいて職務記述書（Job
              Description）を作成し、職務の難易度や責任の重さを定量化する職務評価を行って職務等級（Job
              Grade）に格付けます。
            </div>
          </li>
          <li>
            <div><span>3. </span><span>報酬設計</span></div>
            <div>
              賃金の世間相場や会社の賃金ポリシーに基づき職務ごとに支給する職務給/成果給の上限・標準・下限の適用範囲を決めます。
              <br />また企業・部門業績と個人の貢献度に連動した短期業績賞与の配分基準を決めます。
            </div>
          </li>
          <li>
            <div><span>4. </span><span>個別報酬管理</span></div>
            <div>
              採用・配置する職務内容、業績期待値、人財の採用・リテンション、世間相場の配慮に基づき個別の基本給を決め、定期的な賃金改定を行います。
            </div>
          </li>
          <li>
            <div><span>5. </span><span>人財マネジメント</span></div>
            <div>
              組織責任者を中心に事業目標に基づく組織課題の分析から個人の目標設定、実績の振り返り、業績評価/行動評価、フィードバックを行い、人財の動機づけとキャリア支援、賞与と賃金改定の評定などを行います。
            </div>
          </li>
        </ul>
        <p class="idea_text">
          JOB
          Scopeは、上記それぞれの実務に必要なシンプルなデザインのテンプレートを用意し、大企業はもとより、中堅・中小企業にも使いやすいシームレスな実施・運用のフレームを実現しました。これにより導入に必要な納期が大幅に短縮されます。<br />
          専門コンサルタントとして20年以上の実績を持つ株式会社プライムコンサルタントのノウハウを豊富に取り入れており、とかく複雑・高コストになりがちであった制度設計・導入プロセスを劇的に短期・省力化し、ジョブ型人事制度の導入効果を早期に実現するうえで絶大な効果を発揮します。
        </p>
        <!-- eslint-disable-next-line no-irregular-whitespace -->
        <p>株式会社プライムコンサルタント<br />代表　菊谷寛之</p>
      </div>
      <h2 class="comment_title">
        人事コンサルタント<br class="pc_hide" />コメント
      </h2>
      <div class="design_concept_comment">
        <p class="comment_text">
          VUCA時代・DX・ポストコロナなどにより、あらゆる意味での組織変革が求められる中、昨今注目を集めているのが「ジョブ型人事制度」。ジョブ型企業における人事マネジメントの実務経験上、そこに不可欠なものは「明確に定義された成果責任」と「キャリアにおける自主性・自律性を実現する仕組み」であると私は考えています。<br />
          そして、これらは経営戦略と整合しながら体系的に、且つ一貫性を持って進められなければなりません。<br />
          そのような意味において、組織デザインから職務定義書作成、給与・賞与提案、キャリア形成や育成プランまでカバーできる「JOB<br />
          Scope」は「ジョブ型人事制度」の円滑な実現に貢献できるシステムであると考えます。
        </p>
        <!-- eslint-disable-next-line no-irregular-whitespace -->
        <p>株式会社 We Are The People<br />代表取締役　安田雅彦</p>
      </div>
    </section>
    <section class="plan">
      <picture class="separate">
        <source srcset="../assets/img/pc/plan_top_separate.svg" />
        <img src="../assets/img/pc/plan_top_separate.svg" loading="lazy" alt />
      </picture>
      <div>
        <h2>料金プラン</h2>
        <p>
          料金に関しましては、<br
            v-if="spFlg"
          />お問い合わせよりご連絡ください。<br />別途、当社よりご連絡させていただきます。
        </p>
      </div>
    </section>
    <section class="publish">
      <h2>サービス掲載媒体</h2>
      <p>
        各種メディアにJOB Scopeの紹介記事を掲載<br
          v-if="spFlg"
        />いただきました。
      </p>
      <div class="publish_flex">
        <a
          href="https://dime.jp/genre/1408867/"
          class="publish_item"
          target="_blank"
        >
          <img src="../assets/img/pc/dime.png" alt="dime" />
        </a>
        <a
          href="https://www.weeklybcn.com/journal/feature/detail/20220704_192214.html"
          class="publish_item"
          target="_blank"
        >
          <img src="../assets/img/pc/bcn.png" alt="bcn" />
        </a>
      </div>
    </section>
    <section class="support">
      <img
        class="support_logo"
        src="../assets/img/common/h1.svg"
        loading="lazy"
        alt="JOB Scope "
      />
      <h2>
        DX時代のジョブ型人事制度に特化した、<br
          v-if="spFlg"
        />キャリアマネジメント、<br v-if="!spFlg" />
        組織変革、<br v-if="spFlg" />企業強化を実現する<br
          v-if="spFlg"
        />新しい人事制度変革クラウドサービス
      </h2>
      <div class="btn_box">
        <v-btn
          class="action contact"
          depressed
          link
          color="#F97C40"
          href="https://offers.jobscope.ai/contact"
          target="_blank"
          >デモ/お問い合わせ</v-btn
        >
        <!-- <v-btn
          class="action apply"
          depressed
          link
          color="#F97C40"
          href="https://jobscope.ai/feature/#apply"
          target="_blank"
          >お申し込み</v-btn
        > -->
      </div>
    </section>
    <section class="sns">
      <h2>
        SNSで最新情報を配信中です。<br />
        是非フォローください。
      </h2>
      <div class="sns_content">
        <a href="https://note.com/jobscope/" class="sns_item" target="_blank">
          <img src="../assets/img/pc/note_bg.png" alt="note" />
          <p>note</p>
        </a>
        <a
          href="https://www.facebook.com/JOBScope.HR"
          class="sns_item"
          target="_blank"
        >
          <img src="../assets/img/pc/facebook_bg.png" alt="facebook" />
          <p>Facebook</p>
        </a>
        <a href="https://twitter.com/ScopeJob" class="sns_item" target="_blank">
          <img src="../assets/img/pc/twitter_bg.png" alt="twitter" />
          <p>Twitter</p>
        </a>
      </div>
      <h2>お役立ち記事やブログも発信中です。</h2>
      <div class="sns_content blogs">
        <a
          href="https://marketing.jobscope.ai/organization-transformation"
          class="sns_item"
          target="_blank"
        >
          <picture>
            <source
              media="(max-width: 1023px)"
              srcset="
                ../assets/img/sp/article_bg.png   1x,
                ../assets/img/sp/article_bg@2x.png 2x
                ../assets/img/sp/article_bg@2x.png 3x
              "
            />
            <source
              media="(min-width: 1024px)"
              srcset="
                ../assets/img/pc/article_bg.png   1x,
                ../assets/img/pc/article_bg@2x.png 2x
                ../assets/img/pc/article_bg@2x.png 3x
              "
            />
            <img src="../assets/img/pc/article_bg.png" loading="lazy" alt />
          </picture>
          <p>経営・人事に<br v-if="spFlg" />関する記事</p>
        </a>
        <a href="https://blog.jobscope.ai/" class="sns_item" target="_blank">
          <picture>
            <source
              media="(max-width: 1023px)"
              srcset="
                ../assets/img/sp/blog_bg.png   1x,
                ../assets/img/sp/blog_bg@2x.png 2x
                ../assets/img/sp/blog_bg@2x.png 3x
              "
            />
            <source
              media="(min-width: 1024px)"
              srcset="
                ../assets/img/pc/blog_bg.png   1x,
                ../assets/img/pc/blog_bg@2x.png 2x
                ../assets/img/pc/blog_bg@2x.png 3x
              "
            />
            <img src="../assets/img/pc/blog_bg.png" loading="lazy" alt />
          </picture>
          <p>ブログ</p>
        </a>
      </div>
    </section>
    <section class="annotations">
      <p class="annotation">※JOB Scopeはデフィデ株式会社の登録済商標です。</p>
      <p class="annotation">
        ※JOB Scopeはデフィデ株式会社にて特許出願中です。<br v-if="spFlg" /><span
          v-if="spFlg"
          >&ensp;</span
        >
        （特願番号：2022-68740、2022-68741、2022-68742）
      </p>
    </section>
    <picture class="separate" v-if="spFlg">
      <source srcset="../assets/img/sp/footer_separate.svg" />
      <img src="../assets/img/sp/footer_separate.svg" loading="lazy" alt />
    </picture>
    <section class="footer">
      <picture class="separate" v-if="!spFlg">
        <source srcset="../assets/img/pc/footer_bottom_separate.svg" />
        <img
          src="../assets/img/pc/footer_bottom_separate.svg"
          loading="lazy"
          alt
        />
      </picture>
      <div class="flex">
        <div class="text">
          <div class="link">
            <!-- <router-link to="/privacy">プライバシーポリシー /</router-link> -->
            <router-link to="/terms">利用規約 /</router-link
            ><a href="https://www.defide-ix.com" target="_blank" rel="noopener"
              >運営会社</a
            >
          </div>
          <div class="privacy"></div>
        </div>
        <div class="sns">
          <a
            href="https://note.com/jobscope/"
            class="sns_item"
            target="_blank"
            aria-label="jobscopeのnote"
          >
            <img src="../assets/img/common/note_white.png" alt="" />
          </a>
          <a
            href="https://www.facebook.com/JOBScope.HR"
            class="sns_item"
            target="_blank"
            aria-label="jobscopeのfacebook"
          >
            <img src="../assets/img/common/facebook_white.png" alt="" />
          </a>
          <a
            href="https://twitter.com/ScopeJob"
            class="sns_item"
            target="_blank"
            aria-label="jobscopeのtwitter"
          >
            <img src="../assets/img/common/twitter_white.png" alt="" />
          </a>
        </div>
      </div>
    </section>
    <DialogTemplate />
  </main>
</template>

<script>
import DialogTemplate from "../components/DialogTemplate";
import PromotionTabs from "../components/PromotionTabs";
import MainHeader from "../components/MainHeader";

import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";
export default {
  name: "Main",
  components: {
    DialogTemplate,
    PromotionTabs,
    Hooper,
    Slide,
    HooperPagination,
    MainHeader,
  },
  created() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    document.title =
      "JOB Scope｜日本の人事に最適化したジョブ型人事クラウドサービス";
    this.prodFlg =
      location.hostname === "jobscope.ai" ||
      location.hostname === "www.jobscope.ai" ||
      location.hostname === "hrscape.jp" ||
      location.hostname === "www.hrscape.jp";
    setTimeout(() => {
      this.isBannerSlide = true;
    }, 1000);

    // Hashに#openNewsがあれば、Newsモーダルが表示される
    let hash = window.location.hash;
    if (hash === "#openNews") {
      this.show_modal(1);
    }
  },
  data() {
    return {
      services: [
        {
          title: "経営・事業戦略を屋台骨とした人事の仕組み",
          img: require("../assets/img/pc/service_icon_1.svg"),
          text1: "経営戦略の策定と、それに基づく組織・職務設計",
          text2: "ラインマネージャーによる部門の課題発見と課題解決",
        },
        {
          title: "体系的な人事評価と報酬決定",
          img: require("../assets/img/pc/service_icon_2.svg"),
          text1: "成果目標への評価に基づく透明性のある人事評価",
          text2: "職務に紐づく賃金表と、評価結果と連動する給与査定",
        },
        {
          title: "従業員のリスキルやキャリア支援",
          img: require("../assets/img/pc/service_icon_3.svg"),
          text1: "社員への学習機会提供による自立的キャリア形成の促進",
          text2: "他社との職務待遇比較による、自社職務の待遇改善",
        },
        {
          title: "タレント分析とAI職務魅力度分析",
          img: require("../assets/img/pc/service_icon_4.svg"),
          text1: "職務配置分析による適正人財配置確認と検討",
          text2: "不足する人財の魅力的な待遇検討の求人情報分析レポート",
        },
      ],
      features: [
        {
          img: require("../assets/img/common/feature_1.svg"),
          title:
            "iCDタスク診断<br><span>（スキルマッチ度、スキルセット分析）</span>",
        },
        {
          img: require("../assets/img/common/feature_2.svg"),
          title: "AIリスキリング分析",
        },
        {
          img: require("../assets/img/common/feature_3.svg"),
          title: "タレントマネジメント",
        },
        {
          img: require("../assets/img/common/feature_4.svg"),
          title:
            "キャリアマネジメント<br><span>（経年変化の評価・保有スキル・経験他）</span>",
        },
        {
          img: require("../assets/img/common/feature_5.svg"),
          title:
            "ジョブ型職務マネジメント<span>（目標管理、成果責任、<br>タスク、スキル、コンピテンシー、職務等級設定他）</span>",
        },
        {
          img: require("../assets/img/common/feature_6.svg"),
          title: "キャリアアップシート<br><span>（目標管理と評価）</span>",
        },
        {
          img: require("../assets/img/common/feature_7.svg"),
          title: "職務評価と賃金システムとの連動",
        },
        {
          img: require("../assets/img/common/feature_8.svg"),
          title: "評価プロセス完全自動化",
        },
        {
          img: require("../assets/img/common/feature_9.svg"),
          title: "各種分析レポート",
        },
        {
          img: require("../assets/img/common/feature_10.svg"),
          title: "部門目標と課題表出・KSF/KPI設定",
        },
      ],
      currentSlide: 0,
      tabs: "0",
      spFlg: false,
      prodFlg: false,
      isBannerSlide: false,
    };
  },
  watch: {
    currentSlide: function() {
      this.$refs.hooper.slideTo(this.currentSlide);
      this.tabs = String(this.currentSlide);
    },
  },
  methods: {
    show_modal(i) {
      this.$modal.show(`modal_${i}`);
    },
    slide(value) {
      this.currentSlide = value.currentSlide;
    },
    handleResize() {
      this.spFlg = window.matchMedia("(max-width: 1023px)").matches
        ? true
        : false;
    },
  },
};
</script>

<style scoped lang="scss">
.JOBSCOPE {
  overflow: hidden;
  position: relative;
  & > section {
    position: relative;
  }
  .absolute {
    position: absolute;
    z-index: 1;
  }
  h1,
  h2,
  h3,
  p,
  ul {
    position: relative;
    z-index: 30;
  }
  h1,
  h2,
  h3 {
    color: #080954;
    font-weight: bold;
    font-weight: bold;
  }
  picture {
    display: block;
    position: relative;
  }
  .separate {
    img {
      width: 100%;
    }
  }
  .relative {
    position: relative;
  }
  .top {
    position: relative;
    background-color: #ffffff;
    .it-procedure {
      color: #008000;
    }
    .icd,
    .it {
      background-color: #e0e5f1;
      border-radius: 3px;
      &_img {
        background-color: #ffffff;
      }
      &_head {
        color: #080954;
        font-weight: bold;
      }
      &_exp {
        color: #484848;
      }
    }
    .patent {
      padding: 0;
      color: #484848;
      border: solid 3px #e0e5f1;
      &_inner {
        display: flex;
        margin: 0 auto;
      }
      span {
        color: #ed6d35;
      }
      img {
        height: 53px;
        width: 80px;
      }
    }
    .links {
      display: flex;
      justify-content: center;
      .link_to_ai,
      .link_to_icd {
        a {
          color: #fff;
          font-weight: 500;
          background-color: #99bb55;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background-color: #a7c271;
          }
        }
      }
    }
    .news_btn_arrow {
      margin-left: 4px;
    }
    .sns {
      .sns_item {
        text-align: center;
        img {
          height: 42px;
          width: 42px;
          margin-bottom: 6px;
        }
        p {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
  .overview {
    background-color: #9ad574;
    p,
    figcaption {
      color: #080954;
    }
    .overview_content {
      .img {
        display: flex;
        justify-content: space-between;
        figure {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
    .separate {
      z-index: 3;
    }
  }
  .news {
    position: relative;
    z-index: 32;
    &_title {
      display: flex;
    }
    &_btn {
      height: 28px !important;
      width: 108px;
      color: #ffffff;
      font-size: 11px;
      border-radius: 14px;
    }
    .news_data {
      display: flex;
      padding: 5px 0;
      cursor: pointer;
      &:first-of-type {
        padding-top: 18px;
      }
      .date {
        padding-right: 20px;
        color: #b4b5cb;
        p {
          font-size: 16px;
          line-height: 26px;
        }
      }
      .detail {
        p {
          font-size: 16px;
          line-height: 26px;
        }
        a > p {
          cursor: pointer;
          transition: all 0.2s;
        }
        a > p:hover,
        .detail_link:hover {
          background-color: rgba(242, 246, 255, 0.7);
        }
      }
    }
  }
  .intro {
    background-color: white;
    z-index: 5;
  }
  .system {
    background-color: #e9f6e0;
    .separate_top {
      background-color: white;
    }
    .separate_bottom {
      background-color: white;
    }
  }
  .support_system {
    .separate_bottom {
      background-color: #ffffff;
    }
  }
  .plan {
    z-index: 15;
    .separate_top {
      background-color: #f2f6ff;
    }
    li {
      h3,
      p {
        color: white;
      }
    }
  }
  .step {
    background-color: #e9f6e0;
  }
  .support_system {
    background-color: #ffffff;
  }
  .design_concept {
    background-color: #e9f6e0;
  }
  .publish {
    text-align: center;
    &_flex {
      position: relative;
      display: flex;
      justify-content: space-between;
      z-index: 32;
    }
  }
  .support {
    .sns_content {
      display: flex;
      justify-content: space-between;
      p {
        color: #080954;
        text-align: center;
      }
    }
  }
  .footer {
    .flex {
      align-items: center;
    }
    .sns {
      display: flex;
      justify-content: space-between;
      width: 200px;
      img {
        width: 46px;
        height: 46px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .JOBSCOPE {
    .top {
      margin-top: 120px;
      .top_flex-upper {
        display: flex;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
        .top_content {
          z-index: 6;
          width: 590px;
          h1 {
            position: relative;
            font-size: 48px;
            line-height: 72px;
            letter-spacing: 2px;
            white-space: nowrap;
            span {
              position: absolute;
              font-size: 16px;
              line-height: 1;
              margin-top: 20px;
            }
          }
        }
      }
      .top_flex-lower {
        display: flex;
        justify-content: space-between;
        max-width: 1200px;
        margin: 0 auto;
        h3 {
          font-size: 24px;
        }
        .top_flex-left {
          width: 632px;
          .text {
            font-size: 32px;
            font-weight: bold;
            color: #6e6e6e;
            margin: 22px 0 61px;
            line-height: 1.5;
            letter-spacing: 1.6px;
          }
          .link_to_movie {
            display: flex;
            justify-content: center;
            width: 440px;
            margin-left: 72px;
            position: relative;

            a {
              height: 60px;
              width: 440px;
              color: #fff;
              font-weight: 500;
              background-color: #99bb55;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 30px;
              font-size: 20px;
              letter-spacing: 0.8px;
              &:hover {
                background-color: #a7c271;
              }
            }
            &::before,
            &::after {
              content: url("../assets/img/pc/double_arrow.svg");
              position: absolute;
              z-index: 2;
            }
            &::before {
              left: 24px;
              top: 50%;
              transform: translateY(-50%);
            }
            &::after {
              right: 26px;
              top: 49%;
              transform: translateY(-50%) rotate(180deg);
            }
          }
          .icd,
          .it {
            height: 123px;
            width: 577px;
            display: flex;
            padding: 3px;
            &_img {
              height: 117px;
              width: 98px;
              padding: 12px;
            }
            &_text {
              padding: 12px 16px;
            }
            &_head {
              font-size: 20px;
              margin-bottom: 4px;
            }
            &_exp {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
            }
          }
          .icd {
            margin-top: 57px;
          }
          .it {
            height: 153px;
            margin-top: 30px;
            &_img {
              height: 147px;
              width: 98px;
            }
          }
          .patent {
            height: 60px;
            width: 390px;
            line-height: 60px;
            margin-bottom: 8px;
            padding: 0 20px;
            font-size: 20px;
          }
          .annotation {
            margin: 0 auto;
            font-size: 11px;
            line-height: 21px;
            color: #393838;
            letter-spacing: 0.15px;
            white-space: nowrap;
            &:first-of-type {
              margin-top: 20px;
            }
          }
          .links {
            margin-right: 38px;
            .link_to_ai,
            .link_to_icd {
              a {
                height: 36px;
                width: 212px;
                border-radius: 18px;
                font-size: 18px;
                margin: 50px 20px;
                letter-spacing: 0.8px;
              }
              .btn_arrow {
                margin-left: 12px;
              }
            }
          }
        }
        .top_flex-right {
          width: 50%;
          .sns {
            display: flex;
            justify-content: flex-end;
            margin: 114px 0 16px 0;
            .sns_item {
              letter-spacing: 0.7px;
              margin-left: 20px;
              &:nth-of-type(2) {
                margin-left: 30px;
              }
              &:nth-of-type(3) {
                margin-left: 30px;
              }
              &:nth-of-type(4) {
                margin-left: 18px;
              }
              &:nth-of-type(5) {
                margin-left: 16px;
              }
            }
          }
          .promotion {
            position: relative;
            z-index: 2;
            width: 572px;
            margin-left: 8px;
          }
        }
      }
      @media (max-width: 1400px) {
        .top_flex-upper .top_content h1 {
          font-size: 40px;
          line-height: 60px;
        }
        .top_flex-lower .top_flex-left .text {
          font-size: 30px;
          line-height: 45px;
        }
      }
    }
    .news {
      margin: 30px 0 0 14px;
      &_title {
        margin-left: 12px;
        letter-spacing: 1.92px;

        & h2 {
          font-size: 24px;
        }
      }
      &_btn {
        margin-left: 34px;
      }
    }
    .overview {
      .content_box {
        height: 948px;
        position: relative;
      }
      & .separate_top {
        top: 0;
        position: absolute;
        z-index: 10;
        width: 100%;
        img {
          width: 100%;
        }
      }
      & .separate_bottom {
        bottom: -1px;
        position: absolute;
        z-index: 10;
        width: 100%;
        img {
          width: 100%;
        }
      }
      &_img {
        padding-top: 10px;
        text-align: right;
        img {
          width: 504px;
        }
      }
      .overview_content {
        z-index: 6;
        width: 960px;
        padding-right: 374px;
        position: absolute;
        top: 162px;
        left: 50%;
        transform: translateX(-50%);
        h2 {
          font-size: 36px;
          letter-spacing: 2.88px;
          line-height: 54px;
        }
        .text_1 {
          line-height: 2;
          margin-top: 40px;
          width: 582px;
          sup {
            font-size: 10px;
          }
        }
        .img {
          margin: 20px 0 0 10px;
          figure {
            justify-content: flex-end;
            &:nth-of-type(3),
            &:last-of-type {
              img {
                padding-bottom: 6px;
              }
            }
          }
          img {
            margin-bottom: 8px;
            width: 55px;
          }
          figcaption {
            font-size: 13px;
            line-height: 18px;
            text-align: center;
            font-weight: bold;
            position: relative;

            span {
              top: 16px;
              left: 54px;
              position: absolute;
              font-size: 10px;
            }
            .reference_mark {
              left: 60px;
            }
          }
        }
        ul {
          margin-top: 32px;
          display: flex;
          justify-content: space-between;
          li {
            width: 190px;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 1.5px;
            color: #fff;
            text-align: center;
            height: 63px;
            line-height: 18px;
            letter-spacing: 1.5px;
            border: 2px solid #fff;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        ul + .annotation {
          margin-top: 24px;
        }
        .annotation {
          margin: 6px auto;
          font-size: 11px;
          line-height: 1.64;
          color: #393838;
          letter-spacing: -0.17px;
        }
      }
    }
    .intro {
      h2 {
        font-size: 36px;
        letter-spacing: 2.88px;
        text-align: center;
        line-height: 54px;
      }
      h3 {
        font-size: 24px;
        letter-spacing: 3.6px;
        margin-top: 20px;
        color: #6e6e6e;
        font-weight: 500;
        text-align: center;
        .text_green {
          font-size: 28px;
          color: #81c854;
        }
      }

      ol {
        width: 960px;
        margin: 46px auto 0;
        li {
          display: flex;
          font-weight: bold;
          &:not(&:last-of-type) {
            margin-bottom: 30px;
          }
          .num {
            font-size: 30px;
            letter-spacing: 3px;
            color: #9ad574;
            margin: 4px 30px 0 0;
            border-bottom: 4px solid;
            padding-bottom: 4px;
          }
          & > div:last-of-type {
            color: #393838;
            line-height: 2;
          }
        }
      }
      .text_2 {
        font-size: 24px;
        line-height: 46px;
        letter-spacing: 1.2px;
        color: #6e6e6e;
        font-weight: 500;
        margin: 0 auto;
        white-space: nowrap;
        margin-top: 40px;
        text-align: center;
        .text_green {
          font-size: 28px;
          color: #81c854;
        }
      }
      .separate_bottom {
        margin-top: 28px;
        img {
          width: 100%;
        }
      }
    }
    .system {
      h2 {
        font-size: 30px;
        letter-spacing: 2.4px;
        text-align: center;
        padding-top: 20px;
      }
      ul {
        width: 960px;
        margin: 40px auto 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 470px;
          margin-bottom: 30px;
          h3 {
            font-size: 20px;
            line-height: 1.45;
          }
          .white_line {
            height: 5px;
            background-color: #fff;
            border-radius: 5px;
            margin-top: 15px;
          }
          .flex {
            margin-top: 20px;
            display: flex;

            .text {
              margin-left: 30px;
            }
          }
          p {
            font-size: 16px;
            line-height: 32px;
            position: relative;
            color: #393838;

            &::after {
              content: "";
              position: absolute;
              width: 3px;
              height: 3px;
              border-radius: 50%;
              left: -8px;
              top: 16px;
              background-color: #393838;
            }
          }
        }
      }
    }
    .feature {
      h2 {
        font-size: 30px;
        letter-spacing: 2.4px;
        text-align: center;
        margin-top: 65px;
      }
      .text_1 {
        text-align: center;
        font-size: 24px;
        color: #6e6e6e;
        font-weight: 500;
        line-height: 36px;
        margin-top: 20px;
      }
      .text_2 {
        color: #393838;
        width: 960px;
        margin: 40px auto 0;
        line-height: 2;
      }
      .feature_flex {
        display: flex;
        justify-content: center;
        margin: 45px 0 20px;
        ul {
          margin-right: 50px;
          li {
            display: flex;
            align-items: center;
            border-bottom: dotted 1px #c0c4ce;
            padding: 15px 0;
            width: 452px;
            white-space: nowrap;
            div:first-of-type {
              margin-right: 15px;
            }
            div:last-of-type {
              color: #080954;
              font-size: 18px;
              font-weight: bold;
              line-height: 24px;
              span {
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
        picture {
          padding-top: 20px;
        }
      }
    }
    .step {
      h2 {
        font-size: 30px;
        letter-spacing: 2.4px;
        text-align: center;
      }
      .img_area {
        width: 1015px;
        margin: 40px auto 0;
      }
      .separate {
        margin-top: 40px;
      }
    }
    .support_system {
      h2 {
        font-size: 30px;
        letter-spacing: 2.4px;
        text-align: center;
        padding-top: 40px;
      }
      p {
        margin-top: 32px;
        line-height: 2;
        color: #393838;
      }
      &_flex {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        div:first-of-type {
          width: 373px;
          margin-right: 70px;
        }
        h3 {
          font-size: 24px;
          line-height: 38px;
        }
        .img {
          margin-top: 52px;
        }
      }
      .separate {
        margin-top: 40px;
      }
    }
    .design_concept {
      h2 {
        font-size: 30px;
        letter-spacing: 2.4px;
        text-align: center;
        &:first-of-type {
          padding-top: 40px;
        }
      }
      &_idea {
        width: 960px;
        margin: 0 auto;
        .idea_text {
          color: #393838;
          line-height: 2;
          &:first-of-type {
            margin-top: 36px;
          }
        }
        p {
          &:last-of-type {
            margin-top: 16px;
            text-align: right;
            color: #393838;
            line-height: 2;
          }
        }
        .idea_list {
          margin: 30px 0;
          li {
            display: flex;
            color: #393838;
            justify-content: space-between;
            letter-spacing: 0.32px;
            line-height: 1.5;
            &:not(&:last-of-type) {
              margin-bottom: 16px;
            }
            & > div:first-of-type {
              font-weight: bold;
              span:first-of-type {
                color: #9ad574;
              }
              span:last-of-type {
                color: #080954;
              }
            }
            & > div:last-of-type {
              font-weight: 500;
              width: 790px;
            }
          }
        }
      }
      .comment_title {
        margin-top: 100px;
      }
      &_comment {
        width: 960px;
        margin: 0 auto;
        p {
          color: #393838;
          line-height: 2;
          &:first-of-type {
            margin-top: 36px;
          }
          &:last-of-type {
            margin-top: 56px;
            text-align: right;
          }
        }
      }
    }
    .plan {
      h2 {
        font-size: 30px;
        letter-spacing: 2.4px;
        text-align: center;
        padding-top: 85px;
      }
      p {
        line-height: 2;
        text-align: center;
        color: #393838;
        margin-top: 40px;
      }
    }
    .publish {
      margin-top: 140px;
      h2 {
        font-size: 30px;
        letter-spacing: 2.4px;
        text-align: center;
      }
      p {
        color: #393838;
        margin-top: 40px;
      }
      &_flex {
        width: 448px;
        margin: 30px auto 0;
      }
    }
    .support {
      margin-top: 140px;
      text-align: center;
      position: relative;
      .support_logo {
        width: 275px;
      }
      .flex {
        position: relative;
        width: 640px;
        margin: 0 auto;
        padding-top: 120px;
        .action_left {
          position: absolute;
          top: -15px;
          left: -300px;
          picture:last-of-type {
            position: relative;
            top: -130px;
            right: -100px;
          }
        }
      }
      h2 {
        font-size: 20px;
        line-height: 35px;
        letter-spacing: 3px;
        margin-top: 40px;
        &:last-of-type {
          margin-top: 100px;
        }
      }
      .btn_box {
        display: flex;
        justify-content: center;
        position: relative;
        margin-top: 40px;
        z-index: 30;
        .action {
          width: 300px;
          border-radius: 30px;
          font-size: 25px;
          height: 60px !important;
          font-weight: bold;
          color: white;
          &:not(&:last-of-type) {
            margin-right: 20px;
          }
        }
      }
    }
    .sns {
      margin-top: 100px;
      h2 {
        font-size: 20px;
        line-height: 35px;
        letter-spacing: 3px;
        text-align: center;

        &:last-of-type {
          margin-top: 60px;
        }
      }
      &_content {
        width: 510px;
        margin: 40px auto 0;
        display: flex;
        justify-content: space-between;
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          letter-spacing: 1px;
          color: #080954;
        }
        img {
          height: 90px;
          width: 90px;
          margin-bottom: 12px;
        }
        p {
          font-size: 20px;
          font-weight: bold;
        }

        &.blogs {
          width: 428px;
        }
      }
    }
    section.annotations {
      margin: 80px auto 0;
      max-width: 1300px;
      @media (max-width: 1400px) {
        max-width: 1000px;
      }
      .annotation {
        font-size: 11px;
        line-height: 18px;
        color: #393838;
      }
    }
    .footer {
      position: relative;
      margin-top: 80px;
      .flex {
        bottom: 0;
        height: 82px;
        width: 100%;
        padding: 0 83px 32px 40px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        .text {
          padding-top: 20px;
        }
        .link {
          text-align: center;
          a {
            font-size: 14px;
            display: inline-block;
            color: white;
            &:first-of-type,
            &:nth-of-type(2) {
              margin-right: 4px;
            }
          }
        }
        .privacy {
          display: flex;
          justify-content: center;
          p {
            font-size: 11px;
            text-align: center;
            line-height: 60px;
            display: inline-block;
            margin-right: 20px;
            color: #cccccc;
          }
        }
      }
      .sns {
        margin: 0;
      }
    }
  }
}

@media (max-width: 1023px) {
  .JOBSCOPE {
    picture img {
      max-width: 100%;
    }
    // h1 {
    //   // padding: 13px 0 0 20px;
    //   // background-repeat: no-repeat;
    //   // background-size: contain;
    //   // width: 375px;
    //   // display: flex;
    //   // align-items: center;
    //   img {
    //     width: 233px;
    //     height: 47px;
    //   }
    // }
    h1 {
      font-size: 28px;
      line-height: 40px;
    }
    h3 {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 3px;
    }
    .text_1 {
      font-size: 16px;
      line-height: 28px;
    }
    .top {
      overflow: hidden;
      h1 {
        position: relative;
        margin: 80px 0 0 20px;
        font-size: 22px;
        line-height: 32px;
        text-align: left;
        letter-spacing: 1.1px;
        span {
          position: absolute;
          font-size: 8px;
          line-height: 1;
          margin-top: 8px;
        }
      }
      .text {
        font-size: 16px;
        font-weight: bold;
        color: #6e6e6e;
        margin-top: 10px;
        margin: 10px 0 0 20px;
        line-height: 1.75;
      }
      .link_to_movie {
        display: flex;
        justify-content: center;
        width: 340px;
        margin: 30px auto 0;
        position: relative;

        a {
          height: 50px;
          width: 340px;
          color: #fff;
          font-weight: 500;
          background-color: #99bb55;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
          font-size: 16px;
          letter-spacing: 0.8px;
        }
        &::before,
        &::after {
          content: url("../assets/img/sp/double_arrow.svg");
          position: absolute;
          z-index: 2;
        }
        &::before {
          left: 14px;
          top: 52%;
          transform: translateY(-50%);
        }
        &::after {
          right: 14px;
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
        }
      }
      .links {
        .link_to_ai,
        .link_to_icd {
          a {
            height: 32px;
            width: 150px;
            border-radius: 16px;
            font-size: 16px;
            margin: 40px 10px 20px;
          }
          .btn_arrow {
            margin-top: 2px;
            margin-left: 8px;
          }
        }
      }
      .promotion {
        margin-top: 30px;
      }
      .news_btn {
        margin-left: 24px;
      }
      .top_flex-lower {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        .top_flex-left {
          order: 2;
          display: flex;
          flex-direction: column;
          .annotations {
            order: 2;
            padding: 0 20px;
            .icd,
            .it {
              position: relative;
              width: 335px;
              padding: 4px;
              &_img {
                display: block;
                width: 100%;
                border-radius: 3px 3px 0px 0px;
              }
              &_text {
                padding: 12px 20px;
              }
              &_head {
                position: absolute;
                font-size: 18px;
              }
              &_exp {
                font-size: 14px;
                line-height: 21px;
              }
            }
            .patent {
              height: 60px;
              line-height: 58px;
              margin: 0 auto 12px;
              padding: 0 20px;
              font-size: 16px;
              &_inner {
                width: 300px;
              }
            }
            .annotation {
              color: #393838;
              font-size: 11px;
              line-height: 18px;
              span {
                margin-left: 23px;
                display: block;
              }
            }
          }
          .icd {
            height: 173px;
            margin: 40px auto 0;
            &_img {
              height: 86px;
              img {
                height: 64px;
                width: 69px;
                margin: 12px 0px 0px 53px;
              }
            }
            &_head {
              top: 38px;
              right: 52px;
            }
          }
          .it {
            height: 233px;
            margin: 30px auto 12px;
            &_img {
              height: 104px;
              img {
                height: 84px;
                width: 72px;
                margin: 12px 0px 0px 25px;
              }
            }
            &_head {
              top: 47px;
              right: 25px;
            }
          }
          .news {
            order: 1;
            &_title {
              width: 184px;
              img {
                width: 8px;
                height: 8px;
                margin-left: 3px;
              }
              h2 {
                font-size: 20px;
              }
            }
          }
          .sns {
            order: 4;
            display: flex;
            justify-content: space-between;
            width: 228px;
            margin: 40px auto 0;
            color: #080954;

            &.blogs {
              width: 188px;
              margin-top: 20px;
              a {
                margin-right: 24px;

                &:first-of-type {
                  margin-right: 28px;
                }
              }
            }
          }
          .action_box {
            order: 4;
            width: 300px;
            margin: 50px auto 0;
            .action {
              width: 100%;
              border-radius: 30px;
              font-size: 25px;
              height: 60px !important;
              line-height: 60px;
              font-weight: bold;
              color: white;
            }
            .apply {
              margin-top: 30px;
            }
          }
        }
        .top_flex-right {
          order: 1;
          display: flex;
          flex-direction: column;
        }
        .top_content {
          .text_1 {
            font-size: 14px;
            line-height: 25px;
            margin-bottom: 16px;
            padding: 0 20px;
            sup {
              font-size: 8px;
            }
          }
          .img {
            width: 335px;
            max-width: 100%;
            margin: 0 auto;
            figure {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            img {
              width: 40px;
              height: 40px;
            }
            figcaption {
              margin-top: 3px;
              font-size: 8px;
              font-weight: bold;
              text-align: center;
              line-height: 11px;
            }
          }
          .annotation {
            padding: 0 20px;
            font-size: 11px;
            line-height: 18px;
            color: #393838;
            letter-spacing: 0.15px;
          }
        }
      }
      .left {
        top: 150px;
        left: -156px;
      }
      &_bottom_grass {
        bottom: 4px;
        left: 7px;
      }
      @media (min-width: 768px) {
        h1 {
          span {
            display: inline-block;
            &:last-of-type {
              margin-left: 0;
            }
          }
        }
      }
    }
    .overview {
      margin-top: 96px;
      padding-bottom: 40px;
      h2 {
        font-size: 28px;
        line-height: 32px;
        margin-top: 27px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      .overview_content {
        .text_1 {
          font-size: 14px;
          line-height: 25px;
          margin-top: 24px;
          padding: 0 20px;
        }
        .img {
          width: 335px;
          max-width: 100%;
          margin: 20px auto 0;
          figure {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          img {
            width: 40px;
            height: 40px;
          }
          figcaption {
            margin-top: 3px;
            font-size: 8px;
            font-weight: bold;
            text-align: center;
            line-height: 11px;
          }
        }
        ul {
          margin-top: 20px;
          li {
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 1.5px;
            color: #fff;
            width: 335px;
            margin: 0 auto;
            text-align: center;
            height: 45px;
            line-height: 41px;
            border: 2px solid #fff;
            border-radius: 10px;
            &:not(&:last-of-type) {
              margin-bottom: 10px;
            }
          }
        }
        ul + .annotation {
          margin-top: 20px;
          span {
            display: block;
            margin-left: 28px;
          }
        }
        .annotation {
          padding: 0 20px;
          font-size: 11px;
          line-height: 18px;
          color: #393838;
          letter-spacing: 0.15px;
        }
      }
    }
    .news {
      padding: 40px 20px 0;
      h2 {
        text-align: center;
        line-height: 32px;
        margin-bottom: 16px;
        font-weight: 900;
      }
      .news_flex {
        .news_data {
          &:first-of-type {
            padding-top: 16px;
          }
          &:not(:first-of-type) {
            padding-top: 12px;
          }
          .date {
            padding-right: 16px;
          }
          p {
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
    .overview_img {
      margin-top: -10px;
      position: relative;
      .separate_top,
      .separate_bottom {
        position: absolute;
        z-index: 100;
        width: 100%;
        img {
          height: auto;
          width: 100%;
        }
      }
      .main_img {
        width: 100%;
        img {
          height: 375px;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
      @media (min-width: 640px) {
        .main_img {
          img {
            height: 500px;
          }
        }
      }
      @media (min-width: 840px) {
        .main_img {
          img {
            height: 600px;
          }
        }
      }
      .separate_top {
        top: 0;
      }
      .separate_bottom {
        bottom: 0;
      }
    }
    .intro {
      margin-top: 32px;
      h2 {
        letter-spacing: 0;
        line-height: 1;
        text-align: center;
        line-height: 1.46;
      }
      h3 {
        margin-top: 30px;
        font-size: 22px;
        letter-spacing: 3.2px;
        color: #6e6e6e;
        font-weight: 500;
        line-height: 1.5;
        text-align: center;
        .text_green {
          font-size: 26px;
          color: #81c854;
        }
      }
      ol {
        margin: 14px 20px 0;
        li {
          &:not(&:last-of-type) {
            margin-bottom: 10px;
          }
          .num {
            font-size: 30px;
            font-weight: bold;
            color: #9ad574;
            border-bottom: 4px solid;
            display: inline-block;
            margin-bottom: 12px;
            letter-spacing: 3px;
            line-height: 45px;
          }
          div {
            color: #393838;
          }
          & > div:last-of-type {
            line-height: 32px;
          }
        }
      }
      .text_2 {
        margin-top: 20px;
        font-size: 22px;
        letter-spacing: 3.2px;
        color: #6e6e6e;
        font-weight: 500;
        line-height: 1.5;
        text-align: center;
        .text_green {
          font-size: 26px;
          color: #81c854;
        }
      }

      .separate_bottom {
        margin-top: 30px;
      }
    }
    .system {
      h2 {
        padding-top: 30px;
        line-height: 1.46;
        text-align: center;
      }
      .ul {
        padding: 0 20px 80px;
      }
      .slide_box {
        height: 310px;
        width: 335px;
        margin: 24px auto 0;
        .slide {
          padding: 30px 24px;
          border-radius: 10px;
          border: solid 3px #e0e5f1;
          h3 {
            width: 278px;
            margin-bottom: 12px;
            letter-spacing: normal;
          }
          p {
            margin-left: 24px;
            line-height: 2;
            color: #393838;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              width: 3px;
              height: 3px;
              border-radius: 50%;
              left: -14px;
              top: 16px;
              background-color: #393838;
            }
          }
        }
      }
    }
    .feature {
      h2 {
        font-size: 28px;
        letter-spacing: 0;
        margin-top: 30px;
        text-align: center;
      }
      .text_1 {
        margin-top: 32px;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 3.3px;
        text-align: center;
        color: #6e6e6e;
      }
      .text_2 {
        color: #393838;
        line-height: 2;
        margin: 32px auto 0;
        width: 335px;
      }
      .feature_flex {
        margin-top: 40px;
        padding: 0 20px 40px;
        ul {
          max-width: 500px;
          margin: 0 auto;
          li {
            display: flex;
            align-items: center;
            border-bottom: dotted 0.5px #c0c4ce;
            padding: 12px 0;
            div:first-of-type {
              margin-right: 10px;
              img {
                width: 40px;
                height: 40px;
              }
            }
            div:last-of-type {
              color: #080954;
              font-size: 13px;
              font-weight: bold;
              line-height: 18px;
              white-space: nowrap;
            }
          }
        }
        picture {
          text-align: center;
          margin-top: 40px;
          img {
            width: 336px;
            max-width: 100%;
          }
        }
      }
    }
    .step {
      h2 {
        font-size: 28px;
        letter-spacing: 0;
        padding-top: 20px;
        text-align: center;
      }
      .img_area {
        margin-top: 24px;
        padding: 0 20px;
        text-align: center;
      }
      .separate {
        margin-top: 40px;
      }
    }
    .support_system {
      h2 {
        margin-top: 20px;
        text-align: center;
      }
      h3 {
        white-space: nowrap;
        letter-spacing: 0;
        padding: 0 20px;
        margin-top: 12px;
      }
      p {
        line-height: 2;
        padding: 0 20px;
        color: #393838;
        margin-top: 20px;
      }
      &_flex {
        margin-bottom: 20px;
        picture {
          text-align: center;
          margin-top: 40px;
          img {
            width: 335px;
            height: 473px;
          }
        }
      }
      .separate {
        margin-top: 40px;
      }
    }
    .design_concept {
      h2 {
        padding-top: 28px;
        text-align: center;
      }
      &_idea {
        .idea_text {
          color: #333;
          line-height: 2;
          margin: 30px 20px 0;
        }
        p {
          &:last-of-type {
            margin: 20px 20px 0;
            text-align: right;
            color: #393838;
            line-height: 1.71;
            font-size: 14px;
          }
        }
        .idea_list {
          margin: 24px 20px 0;
          li {
            letter-spacing: 0.32px;
            line-height: 1.5;
            &:not(&:last-of-type) {
              margin-bottom: 20px;
            }
            & > div:first-of-type {
              font-weight: bold;
              margin-bottom: 8px;
              span:first-of-type {
                color: #9ad574;
              }
              span:last-of-type {
                color: #080954;
              }
            }
            & > div:last-of-type {
              font-weight: 500;
            }
          }
          div {
            color: #393838;
          }
        }
      }
      &_comment {
        padding-bottom: 30px;
        p {
          color: #333;
          line-height: 2;
          margin: 30px 20px 0;
          &:last-of-type {
            margin: 20px 20px 0;
            text-align: right;
            color: #393838;
            line-height: 1.71;
            font-size: 14px;
          }
        }
      }
      @media (min-width: 768px) {
        br {
          display: none;
        }
      }
    }
    .plan {
      h2 {
        padding-top: 30px;
        text-align: center;
      }
      p {
        color: #393838;
        margin-top: 10px;
      }
      .money {
        background-image: url("../assets/img/common/plan.svg");
        background-size: contain;
        width: 335px;
        height: 125.6px;
        margin: 0 auto 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .money_child {
          width: 100%;
          padding: 0 22px;
          div {
            display: inline-block;
            font-size: 19px;
            font-weight: bold;
            color: white;
            line-height: 38px;
            &:first-of-type {
              width: 5em;
              white-space: nowrap;
            }
            .yen {
              font-size: 16px;
            }
            .per {
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
      p {
        line-height: 2;
        text-align: center;
      }
    }
    .publish {
      margin-top: 100px;
      h2 {
        font-size: 30px;
      }
      p {
        line-height: 2;
        color: #393838;
        margin-top: 10px;
      }
      &_flex {
        width: 334px;
        margin: 30px auto 0;
        img {
          width: 156px;
          height: 65px;
        }
      }
    }
    .support {
      margin-top: 100px;
      margin-bottom: -36px;
      text-align: center;
      h2 {
        font-size: 16px;
        line-height: 30px;
        margin-top: 20px;
        letter-spacing: 2.4px;
      }
      .support_logo {
        width: 275px;
        height: 58px;
      }
      .btn_box {
        width: 300px;
        margin: 30px auto;
        display: flex;
        flex-direction: column;
        .action {
          width: 100%;
          border-radius: 30px;
          font-size: 25px;
          height: 60px !important;
          line-height: 60px;
          font-weight: bold;
          color: white;
          &:not(&:last-of-type) {
            margin-bottom: 30px;
          }
        }
      }
    }
    section.sns {
      padding-top: 100px;
    }
    .sns {
      margin-bottom: 40px;
      h2 {
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 2.4px;
        text-align: center;

        &:last-of-type {
          margin-top: 40px;
        }
      }
      &_content {
        width: 228px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        p {
          text-align: center;
          line-height: 18px;
        }

        a {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #080954;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 0.7px;
        }

        &.blogs {
          width: 165px;

          a {
            margin-top: 20px;

            &:first-of-type {
              img {
                margin-right: 10px;
              }
            }
          }
        }
      }
      img {
        height: 42px;
        width: 42px;
        margin-bottom: 8px;
      }
      .separate {
        img {
          width: 100%;
        }
      }
    }
    section.annotations {
      margin: 40px 0 26px 20px;
      .annotation {
        font-size: 11px;
        line-height: 18px;
        color: #393838;
      }
    }
    .footer {
      height: 160px;
      background-color: #9ad574;
      .flex {
        padding: 20px 0 0 20px;
      }
      .sns {
        padding-top: 20px;
      }
      .link {
        a {
          color: white;
          font-size: 14px;
          &:first-of-type,
          &:nth-of-type(2) {
            margin-right: 4px;
          }
        }
      }
      .text {
        p {
          font-size: 11px;
          color: #cccccc;
          margin-bottom: 3px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.JOBSCOPE {
  .hooper-pagination {
    bottom: -40px;
    .hooper-indicators li {
      &:not(:last-of-type) {
        margin-right: 14px;
      }
    }
    .hooper-indicator {
      background-color: #d2d2d2;
      width: 7px;
      height: 7px;
      &.is-active {
        background-color: #080954;
      }
    }
  }
  .hooper-slide {
    height: auto;
  }

  .feature_flex {
    ul {
      li {
        div:last-of-type {
          span {
            font-size: 16px;
            font-weight: 500;
            @media (max-width: 1023px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
