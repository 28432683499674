<template>
  <main class="CX_privacy common-style-1">
    <Header />
    <div>
      <h2>
        <v-btn depressed link to="/"><div></div></v-btn>プライバシーポリシー
      </h2>
      <section class="inner">
        <div class="first">
          <h3>第１条（個人情報の取得方法とその目的）</h3>
          <p>
            当サービスでは、入力フォームにて利用者の氏名、メールアドレスなどの個人情報を取得します。
            <br />ご提供いただいた個人情報の利用目的は以下の通りです。
          </p>
          <ul class="list ml">
            <li>本サービスを提供するにあたり、その運営管理をするため</li>
            <li>
              本サービスに関するご案内、サポート、利用者からのお問い合わせ、苦情、紛争、訴訟等への対応のため
            </li>
            <li>本サービスの改善に必要なデータを解析又は分析するため</li>
            <li>
              本サービスにおいての市場調査、ならびにデータ分析およびサービスの研究や開発のため
            </li>
            <li>
              本サービスにて、居住エリア等の属性にあわせて最適化された情報の提供
            </li>
            <li>
              個人を特定できない範囲内での本サービスに関する統計情報の作成、利用、開示
            </li>
            <li>
              個人を特定できない範囲内での当社、当社提携先、その他第三者へのマーケティング資料としての情報提供
            </li>
          </ul>
          <p>
            なお、本サービスから取得した個人情報を他の情報源で得た情報で補足することはございません。
          </p>
        </div>
        <div>
          <h3>第２条（個人情報の第三者への提供）</h3>
          <p>
            本サービスは、次の場合を除いて利用者の個人情報を本サービス以外の第三者に開示することはありません。
          </p>
          <ul class="list ml">
            <li>ご本人様の同意がある場合</li>
            <li>法令に基づく場合</li>
            <li>
              人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合
            </li>
            <li>
              公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合
            </li>
            <li>
              国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼす恐れがある場合
            </li>
          </ul>
          <br />
          <p>
            当社は、本サービス運営のため、次範囲内で個人データを共同利用いたします。
          </p>
          <ul class="terms_list_joint">
            <li>
              <p>（1）共同利用する個人情報の項目</p>
              <p>本サービスのご契約管理者氏名、メールアドレス</p>
            </li>
            <li>
              <p>（2）共同して利用する者の範囲</p>
              <p>
                提携先企業
                <a href="https://www.primec.co.jp/" target="_blank"
                  >株式会社プライムコンサルタント</a
                >
              </p>
            </li>
            <li>
              <p>（3）共同して利用する者の利用目的</p>
              <p>
                本サービスの運営に際して、ご契約企業の管理者様にご連絡をするため
              </p>
            </li>
            <li>
              <p>
                （4）共同して利用する個人情報の管理について責任を有する者の氏名又は名称
              </p>
              <p>デフィデ株式会社</p>
            </li>
          </ul>
        </div>
        <div>
          <h3>第３条（クッキー・ログファイル等の技術的情報）</h3>
          <!--prettier-ignore-->
          <p>
        本サービスでは、ログファイルの収集を行っております。
        <br />ログファイルについては、個人情報と関連づけることはなく、セキュリティ対策として監査証跡や障害時の調査等に使用しています。
        <br />クッキーについては、分析と改善のために Google Analyticsを使用しています。
        <br />アクセス情報の収集方法および利用方法については、<a href="https://support.google.com/analytics/answer/4597324" target="_blank">Google Analyticsのポリシー</a>を参照してください。
        <br />これら、Google Analyticsが行うデータ処理を無効にする方法については<a href="https://tools.google.com/dlpage/gaoptout" target="_blank">Google Analytics オプトアウトアドオン</a>をご覧ください。
        <br />利用者は当ウェブサイトを利用することにより Googleが行うデータ処理について同意することを示します。
      </p>
        </div>
        <div>
          <h3 class="nowrap">
            第４条（個人情報に関する開示・訂正・利用停止等の方法）
          </h3>
          <p>
            本サービスにご提供いただいた個人情報の確認、訂正、利用停止等のご依頼については、本サービスの個人情報の取扱い問い合わせ窓口までご連絡ください。
            <br />その際には、ご本人であることを確認した上で対応いたします。
          </p>
        </div>
        <div>
          <h3>第５条（従業者への監督教育管理）</h3>
          <p>
            当社では従業者に対して、定期的に個人情報保護を含むセキュリティに関する教育・啓発を行っております。
          </p>
        </div>
        <div>
          <h3>第６条（セキュリティ上の安全対策）</h3>
          <p>
            当社では、漏えい、改ざん、滅失、紛失等を防ぐためにファイアーウォール、ウィルススキャン、通信の暗号化等の適切なセキュリティ対策を実行し、情報漏えいリスクに備えております。
          </p>
        </div>
        <div>
          <h3>第７条（個人情報の共同利用）</h3>
          <p>
            当社は、特定の第三者との間で個人データを共同利用する場合は、直接ご本人に、個人データを特定の者との間で共同して利用する旨、共同して利用される個人データの項目、共同して利用する者の範囲、利用する者の利用目的、および当該個人データの管理について責任を有する者の名称を通知するか、あるいは本ホームページで公表します。
          </p>
        </div>
        <div>
          <h3>第８条（リンク先の個人情報の取扱い）</h3>
          <p>
            本サービスには外部リンクが存在します。本プライバシーステートメントの対象範囲は当サイトのみとなり、リンク先ウェブサイトでの個人情報の取り扱いについては、当該サイトの利用規約及びプライバシーステートメント等をご参照ください。
          </p>
        </div>
        <div>
          <h3>第９条（個人情報の保存期間）</h3>
          <p>
            当社が必要と判断した場合を除き、利用者がJOB
            Scopeアカウントを削除した時点で個人を識別するデータ(氏名、メールアドレス)を削除いたします。
          </p>
        </div>
        <div>
          <h3>第１０条（プライバシーステートメントの変更・更新）</h3>
          <p>
            当社が個人情報の取扱いの内容を変更する場合には、その内容について本サービス上で通知します。
            <br />また、当初の個人情報の取扱いに記載された内容とは異なる目的で個人情報を使用する場合には、事前に会員の同意を得た上で使用いたします。
            <br />もし、ご同意いただけない場合には、ご自身の判断により、利用を拒否することができます。
          </p>
        </div>
        <div>
          <h3>第１１条（個人情報に関する、苦情・相談・お問合せ窓口）</h3>
          <p>
            〒810-0001<br />福岡市中央区天神1-10-20-8F 天神ビジネスセンター<br />デフィデ株式会社<br />個人情報保護相談窓口<br />個人情報保護管理者：品質管理部部長<br />TEL：092-791-5909
          </p>
        </div>
      </section>
    </div>
    <Footer />
  </main>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Privacy",
  components: {
    Header,
    Footer,
  },
  mounted() {
    document.title = "プライバシーポリシー | JOB Scope";
  },
};
</script>

<style lang="scss" scoped>
.CX_privacy {
  .first {
    margin-top: 0 !important;
  }
  .inner a {
    color: #4457b5;
  }
  .nowrap {
    white-space: nowrap;
  }
  .terms_list_joint li p:first-child {
    margin-left: 0px;
  }
}
@media (max-width: 767px) {
  .sp-hide {
    display: none;
  }
  .terms_list_joint li p:not(:first-child) {
    margin-left: 20px;
  }
}
@media (min-width: 768px) {
  .pc-hide {
    display: none;
  }
}
</style>
