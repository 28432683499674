import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";
import Contact from "../views/Contact.vue";
import Apply from "../views/Apply.vue";
import Terms from "../views/Terms.vue";
import Privacy from "../views/Privacy.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    path: "/contact/:id?",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/apply/:id?",
    name: "Apply",
    component: Apply,
  },
  {
    path: "/terms/",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/privacy/",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "*",
    name: "Notfound",
    component: Main,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  //eslint-disable-next-line
  gtag("config", "G-GFPD13CWGM", { page_path: `${to.path}` });
  window.scroll({ top: 0 });
});

export default router;
