<template>
  <div class="modal_wrapper">
    <modal
      name="modal_1"
      width="90%"
      height="auto"
      :maxWidth="1200"
      :adaptive="true"
    >
      <section class="news">
        <div class="modal_fixed">
          <button class="modal_button" @click="hide_modal(1)">
            <img src="../assets/img/common/close_modal.svg" alt="" />
          </button>
        </div>
        <div class="pc_flex">
          <div><h2>News</h2></div>
          <div class="news_flex">
            <div class="news_data">
              <div class="date"><p>2022/12/15</p></div>
              <div class="detail">
                <h3>「JOB Scope」の紹介サイトをリニューアルしました。</h3>
                <p>
                  人事制度改革プラットフォーム「JOB
                  Scope」に関して、紹介サイトをリニューアルしました。
                  人事制度改革モデル「JOB SCOPE
                  MODEL」として、人事の目的・有り方・ポリシーをベースに各企業様に最適化した人事モデルを構築し、人事の型（モデル）を企業文化として定着を図ります。
                  サービス紹介や機能紹介の他、セミナー動画視聴やホワイトペーパーのダウンロードもこちらから行なっていただけます。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/11/15</p></div>
              <div class="detail">
                <h3>
                  AIによる求人情報・給与分析情報の無料公開を開始しました。
                </h3>
                <p>
                  経営改革クラウドサービス「JOB
                  Scope」で提供する企業や職務の魅力度を分析するAI職務魅力度分析の一機能である求人情報・給与分析データを無料公開しました。
                  経営層や人事部門ではサービスを利用することで、自社の職務の労働市場における価値判断が簡単に行えるだけでなく、参考にすることで自社の職務待遇改善や魅力度向上にご活用いただけます。
                  <br />
                  <a
                    href="https://marketing.jobscope.ai/salary-analysis"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >■AI職務魅力度分析(一部機能公開)</a
                  >
                  <br />
                  <a
                    href="https://www.atpress.ne.jp/news/333007"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >■プレスリリース</a
                  >
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/11/01</p></div>
              <div class="detail">
                <h3>
                  スキルを可視化するAIリスキリングの無償提供を開始しました。
                </h3>
                <p>
                  情報処理推進機構（IPA）が提供するiCD（iコンピテンシ
                  ディクショナリ）のデータを活用した職務に必要なスキルセットや知識の見える化、職務間の相関をサンキーグラフで表示するサービスを無償で提供開始しました。将来目指す職務や獲得すべきスキルが体系的に理解できるため、将来のキャリアパスを計画する上で非常に便利なサービスとなっています。
                  <br />
                  <a
                    href="https://marketing.jobscope.ai/icd-reference-service"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >■AIリスキリング(一部機能公開)</a
                  >
                  <br />
                  <a
                    href="https://www.atpress.ne.jp/news/332251"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >■プレスリリース</a
                  >
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/10/31</p></div>
              <div class="detail">
                <h3>
                  「HR EXPO 2022秋」及び「HRX
                  2022」の出展レポートを公開しました。
                </h3>
                <p>
                  10月開催の人事関連の展示会2件に、ジョブ型人事制度に特化したグローバル標準の総合型クラウドサービスとなる当社の「JOB
                  Scope」を出展し、各展示会の出展レポートを公開いたしました。<br />
                  多くの企業様にブースにお立ち寄りいただき、さまざまなご意見等も伺えた貴重な展示会となりました。<br />
                  ご来場いただきました皆さまに、厚く御礼申し上げます。<br />
                  <a
                    href="https://note.com/jobscope/n/n1884bb41bc13"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >■HR EXPO 2022秋：出展レポート</a
                  >
                  <br />
                  <a
                    href="https://note.com/jobscope/n/n7c3786228c76"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                  >
                    ■HRX2022：出展レポート</a
                  >
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/10/19</p></div>
              <div class="detail">
                <h3>
                  「JOB Scope」のサービス名称が登録商標として承認されました。
                </h3>
                <p>
                  ジョブ型人事制度に特化したグローバル標準の総合型クラウドサービスとなる「JOB
                  Scope」におけるサービス機能となる『AIリスキリング』及び『AI職務魅力度分析』の商標登録申請が承認されました。各々、AIによる従業員のリスキリング支援、オンライン上の求人情報・公的統計情報をAIで分析し、自社の職務待遇の検討を支援するサービス機能となります。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/10/18</p></div>
              <div class="detail">
                <h3>
                  福岡市STARTUP CAFE及びJ-Startup
                  KYUSHUのサポーターに認定されました。
                </h3>
                <p>
                  福岡市が「グローバル創業・雇用創出特区」として企業の創業支援を行なう<a
                    href="https://startupcafe.jp/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >「STARTUP CAFE」</a
                  >と九州経済産業局が推進するスタートアップ企業の育成支援プログラム<a
                    href="https://www.kyushu.meti.go.jp/seisaku/sogyo/oshirase/220412_1.html"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >「J-Startup KYUSHU」</a
                  >において、当社の「JOB
                  Scope」がサポーターとして認定されました。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/10/12</p></div>
              <div class="detail">
                <h3>経営・人事に関するコラムを公開しました。</h3>
                <p>
                  第1弾は
                  <a
                    href="https://marketing.jobscope.ai/organization-transformation"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >『いま再考すべき中小企業の競争力を強化する人・組織の8つの視点』</a
                  >です。<br />
                  (今後、さらに深堀した記事を公開していきます。)<br /><br />
                  <a
                    href="https://marketing.jobscope.ai/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >コラム</a
                  >では、組織改革や人的資本経営などを中心とした、経営層や人事責任者向けのビジネス系記事の他、<br />ジョブ型人事などの人事ご担当者向けの人事系記事を公開予定です。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/10/05</p></div>
              <div class="detail">
                <h3>人事総合EXPO2022 Autumnに出展します。</h3>
                <p>
                  年間約4,000万人に活用されているIT製品の比較サイト「ITトレンド」主催のオンライン展示会に出展します。
                  当社は、日本初ジョブ型人事制度に特化したグローバル標準の総合型クラウドサービス「JOB
                  Scope」を出展の上、オンラインセミナーを実施いたします。<br /><br />
                  開催期間：10月6日（木）～10月7日（金）<br />
                  オンラインセミナー：10月7日（金）16:45～17:15<br />
                  ※セミナー参加は無料となりますが、事前登録が必要となります。<br />
                  <a
                    href="https://bit.ly/3SANPZr"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >■人事総合EXPO2022 参加申込</a
                  >
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/09/28</p></div>
              <div class="detail">
                <h3>「JOB Scope」がIT導入支援ツールに認定されました。</h3>
                <p>
                  経済産業省が実施する<a
                    href="https://www.it-hojo.jp/first-one/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >「IT導入補助金2022」</a
                  >において、「JOB
                  Scope」がIT導入支援ツールに認定されました。補助金の対象となる企業様の申請により、30万円～150万円（補助率1/2以内）の補助金が支給されます。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/09/12</p></div>
              <div class="detail">
                <h3>「JOB Scope」がiCD協会認定システムに認定されました。</h3>
                <p>
                  <a
                    href="https://www.icda.or.jp/about-icda/profile/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >iCD協会</a
                  >は、独立行政法人情報処理推進機構(IPA)が公開した「iコンピテンシ
                  ディクショナリ」(iCD)に関する調査及び研究、普及啓発及び指導、情報の収集及び提供等を行うことにより企業目標の達成を支援し、我が国の産業経済発展に寄与することを目的とする機関となります。
                  実際の企業への活用を通じてiCDの有効性を確認し、さらに全国に向けて普及促進を図るための
                  <a
                    href="https://www.icda.or.jp/authentication/user/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >iCD活用企業認証制度</a
                  >において、「JOB Scope」は
                  <a
                    href="https://www.icda.or.jp/authentication/icd-system/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >iCD協会認定システム</a
                  >へ認定されました。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/09/06</p></div>
              <div class="detail">
                <h3>日本能率協会主催「HRX2022」に出展します。</h3>
                <p>
                  40年以上にわたり人事・人材開発分野の専門カンファレンスを行っている日本能率協会主催の人事・バックオフィス分野のエキスパートが一堂に集う展示会に「JOB
                  Scope」を出展し、セミナーを実施します。
                  <br />事前申込により無料でご来場いただけます。
                  <br />
                  <br />開催期間：10/26（水）～10/28（金）
                  <br />開催場所：東京ビッグサイト 西展示棟 <br /><a
                    href="https://bit.ly/3KORfVr"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >■HRX2022公式サイト</a
                  >
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/09/06</p></div>
              <div class="detail">
                <h3>第11回HR EXPO（人事労務・教育・採用）に出展します。</h3>
                <p>
                  企業価値を高める 人的資本経営を実現！
                  日本最大の人事部門を支援する展示会となるHR EXPOに「JOB
                  Scope」を出展し、セミナーを実施します。
                  <br />事前申込により無料でご来場いただけます。
                  <br />
                  <br />開催期間：10/12（火）～10/14（金）
                  <br />開催場所：幕張メッセ（9-11ホール） <br /><a
                    href="https://bit.ly/3wYCIRv"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >■HR EXPO【秋】公式サイト</a
                  >
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/08/25</p></div>
              <div class="detail">
                <h3>「人的資本経営コンソーシアム」の会員企業となりました。</h3>
                <p>
                  経済産業省と金融庁が支援する<a
                    href="https://bit.ly/3BABU6I"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >「人的資本経営コンソーシアム」</a
                  >が本日設立されました。日本を代表する大手企業や銀行をはじめ320社が参加するコンソーシアムです。
                  JOB
                  Scopeを運営する当社も会員企業として設立総会に参加させていただきました。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/08/10</p></div>
              <div class="detail">
                <h3>
                  日本人材ニュース ONLINEに「JOB
                  Scope」の紹介記事が掲載されました。
                </h3>
                <p>
                  ジョブ型人事制度への注目が高まっている現状において、多くの人事システムはこれまでのメンバーシップ型人事制度を意識した設計になっている中、デフィデの「JOB
                  Scope」は、組織強化を目指すためのジョブ型人事制度に特化した総合型クラウドサービスとして、<a
                    href="https://bit.ly/3BR3SwL"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >日本人材ニュース ONLINE</a
                  >にて紹介されています。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/08/01</p></div>
              <div class="detail">
                <h3>JOB Scope サービスサイトを公開しました。</h3>
                <p>
                  人的資本経営を実現する人が主役のグローバル標準人事クラウドサービスとなる『JOB
                  Scope』の<a
                    href="https://offers.jobscope.ai/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >『JOB Scope』のサービスサイト</a
                  >を公開しました。
                  <br />講演・セミナー・機能紹介動画の視聴やホワイトペーパーのダウンロードを行なっていただけます。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/07/15</p></div>
              <div class="detail">
                <h3>JOB Scope/ブログサイトを公開しました。</h3>
                <p>
                  JOB
                  Scope編集部からの情報をはじめ、提携人事コンサルタント及び社労士の方々からの情報も発信する<a
                    href="https://blog.jobscope.ai/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >JOB Scopeのブログサイト</a
                  >を公開しました。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/07/04</p></div>
              <div class="detail">
                <h3>週刊BCNに「JOB Scope」の紹介記事が掲載されました。</h3>
                <p>
                  注目のITベンダーの戦略、ITユーザーの導入事例など、法人向けIT市場のビジネスパーソンに有益な情報を網羅的に発信するwebメディアとなる<a
                    href="https://www.weeklybcn.com/journal/feature/detail/20220704_192214.html"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >「週刊BCN」</a
                  >において、「機運高まる『ジョブ型』の現在地&ensp;
                  大手ITベンダーが進める人材マネジメントの要諦」の特集にて、
                  「JOB Scope」が紹介されました。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/06/29</p></div>
              <div class="detail">
                <h3>オンラインセミナー開催のご案内</h3>
                <p>
                  当社パートナー企業となる（株）組織デザイン研究所様主催の<a
                    href="https://bit.ly/3HXX55D"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >オンラインセミナー</a
                  >に講師として参加いたします。
                  先ずは、今注目されている人的資本経営について理解を深めていただき、組織の活性化、適切な人事評価、キャリア形成を促す「JOB
                  Scope」を紹介させていただきます。
                  <br />
                  <br />・テーマ：「企業や組織の競争力アップを目指す人事制度とは」
                  <br />・開催日時：7/26(火) 11:00 〜 12:00
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/06/22</p></div>
              <div class="detail">
                <h3>
                  HRカンファレンスのセミナー動画・レポートが公開されました。
                </h3>
                <p>
                  日本の人事部主催 「HRカンファレンス2022」
                  において、5/24に特別講演を実施したセミナーの<a
                    href="https://jinjibu.jp/hr-conference/report/r202205/report.php?sid=2735"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >レポート</a
                  >及び<a
                    href="https://jinjibu.jp/materials/detl/14381/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >動画</a
                  >が公開されました。
                  <a
                    href="https://wearethepeople.jp/profile/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >安田 雅彦氏</a
                  >（（株）We Are The People 代表取締役）のセミナー及び「JOB
                  Scope」の紹介がご覧いただけます。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/06/20</p></div>
              <div class="detail">
                <h3>@DIMEに「JOB Scope」の紹介記事が掲載されました。</h3>
                <p>
                  小学館が発行するトレンドマガジン「DIME」が運営する<a
                    href="https://dime.jp/genre/1408867/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >@DIME</a
                  >において、ジョブ型人事制度を導入した上で、人財育成や組織戦略、経営戦略の構築・実行まで支援する新しい時代の人財と企業のあり方を示唆するサービスとして、「JOB
                  Scope」が紹介されました。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/06/10</p></div>
              <div class="detail">
                <h3>
                  プレスリリース配信：「HR Techイベント」に参加の上「JOB
                  Scope」を紹介
                </h3>
                <p>
                  人事院人事官・伊藤
                  かつら氏の基調講演が行われる次世代マーケティングプラットフォーム研究会主催の
                  <a
                    href="https://nmp-hrtech.peatix.com/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >「HR Techイベント」</a
                  >開催に関して、<a
                    href="https://prtimes.jp/main/html/rd/p/000000020.000004868.html"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >プレスリリース</a
                  >が配信されました。<br />
                  当社もパネルディスカッションに参加の上、「JOB
                  Scope」の紹介を行ないます。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/06/06</p></div>
              <div class="detail">
                <h3>
                  プレスリリース配信：「九州デジタルイノベーション 2022」にJOB
                  Scopeが出展
                </h3>
                <p>
                  日経BP主催の、あらゆる業種・職種のDXを網羅したデジタル変革専門イベントで、3年ぶりにリアル開催となる
                  <a
                    href="https://ers.nikkeibp.co.jp/user/contents/2022x0628kdi/exhibitor.html"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >「九州デジタルイノベーション 2022」</a
                  >への『JOBScope』の出展が、<a
                    href="https://prtimes.jp/main/html/rd/p/000000019.000004868.html"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >プレスリリース配信</a
                  >されました。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/06/01</p></div>
              <div class="detail">
                <h3>
                  BOXIL EXPOのサービスピッチにて、「JOB
                  Scope」のサービス紹介が配信されます。
                </h3>
                <p>
                  06/07-06/09にオンラインにて開催される
                  <a
                    href="https://expo.boxil.jp/event/hr-2022-spring/_nca7S3-"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >BOXIL EXPO
                    人事・総務・法務展の「1分でわかるサービスピッチ」</a
                  >において、「JOB
                  Scope」のサービス紹介動画が配信されます。視聴は無料となりますが、事前登録が必要となります。
                  <br />配信日程：6/7（火）16:25～16:35
                  <br />開催場所：オンライン
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/05/30</p></div>
              <div class="detail">
                <h3>
                  次世代マーケティングプラットフォーム研究会のHR
                  Techセミナーに参加いたします。
                </h3>
                <p>
                  <a
                    href="https://nmp-hrtech.peatix.com/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >「HR Tech：リスキリング
                    ジョブ型人事制度は日本とマーケティングをどう変えるのか」</a
                  >をテーマに、人事院人事官・伊藤
                  かつら氏の基調講演他、セミナー及びパネルディスカッションが開催され、当社も同セミナーに参加の上、「JOB
                  Scope」の紹介を行ないます。
                  <br />開催日程：6/23（木）18:00-21:00
                  <br />開催場所：アンドデジタル株式会社セミナールーム（ソウルドアウト内）
                  <br />※リアル、オンライン同時開催
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/05/26</p></div>
              <div class="detail">
                <h3>
                  6/28（火）～6/29（水）開催の<a
                    href="https://expo.nikkeibp.co.jp/dinv/kys/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >九州デジタルイノベーション 2022</a
                  >に出展いたします。
                </h3>
                <p>
                  日経BP主催のあらゆる業種・職種のDXを網羅したデジタル変革専門イベントとなる「九州デジタルイノベーション
                  2022」に、「JOB Scope」を出展します。
                  <br />開催期間：6/28（火）～6/29（水）
                  <br />開催場所：福岡国際会議場
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/05/26</p></div>
              <div class="detail">
                <h3>
                  日本の人事部主催<a
                    href="https://jinjibu.jp/hr-conference/202205/program.php?sid=2735&rtsid=4638"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                  >
                    「HRカンファレンス2022」 </a
                  >において、5/24に特別講演を実施しました
                </h3>
                <p>
                  定員50名のオンラインセミナーとなりますが、130名程度のお申込みをいただき、ジョブ型人事制度への関心度の高さが伺えました。
                  <a
                    href="https://wearethepeople.jp/profile/"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                    >安田 雅彦氏</a
                  >によるジョブ型の人事制度の本質をテーマにしたセミナーと併せて、「JOB
                  Scope」の紹介を行ないました。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/05/16</p></div>
              <div class="detail">
                <h3>エレベーターサイネージ広告の配信開始いたしました</h3>
                <p>
                  日本初ジョブ型人事制度に特化した総合型クラウドサービス「JOB
                  Scope」のエレベーターサイネージ広告の配信を本日より開始しました。
                  都内の主要なビルにてエレベーターのサイネージ広告も配信します。
                  CM動画は
                  <a
                    href="https://youtu.be/JkuJvjgoqRE"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                  >
                    Youtube
                  </a>
                  でもご覧いただけます。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/05/09</p></div>
              <div class="detail">
                <h3>
                  タクシー広告を本日より東京都内にて開始
                  プレスリリースを配信いたしました
                </h3>
                <p>
                  「JOB Scope」のタクシー広告を本日より東京都内にて開始し、
                  <a
                    href="https://prtimes.jp/main/html/rd/p/000000018.000004868.html"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                  >
                    プレスリリース
                  </a>
                  を配信いたしました。CM動画は
                  <a
                    href="https://youtu.be/JkuJvjgoqRE"
                    target="_blank"
                    rel="noopener"
                    class="detail_link"
                  >
                    Youtube
                  </a>
                  でもご覧いただけます。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/04/27</p></div>
              <div class="detail">
                <h3>SNSにて情報公開を開始しました</h3>
                <p>
                  <a
                    href="https://note.com/jobscope/"
                    class="detail_link"
                    target="_blank"
                    rel="noopener"
                  >
                    Note</a
                  >、
                  <a
                    href="https://www.facebook.com/JOBScope.HR "
                    class="detail_link"
                    target="_blank"
                    rel="noopener"
                  >
                    Facebook</a
                  >、
                  <a
                    href="https://twitter.com/ScopeJob"
                    class="detail_link"
                    target="_blank"
                    rel="noopener"
                  >
                    Twitter
                  </a>
                  にて、「ジョブ型人事制度」及び「JOB
                  Scope」に関する情報公開を開始しました。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/04/25</p></div>
              <div class="detail">
                <h3>
                  安田 雅彦氏（（株）We Are The People
                  代表取締役）による特別講演を開催
                </h3>
                <p>
                  日本の人事部主催<a
                    href="https://jinjibu.jp/hr-conference/202205/program.php?sid=2735&rtsid=4638"
                    class="detail_link"
                    target="_blank"
                    rel="noopener"
                    >「HRカンファレンス2022」</a
                  >において、<a
                    href="https://wearethepeople.jp/profile/"
                    class="detail_link"
                    target="_blank"
                    rel="noopener"
                    >安田 雅彦氏（（株）We Are The People 代表取締役）</a
                  >による特別講演を開催します。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/04/21</p></div>
              <div class="detail">
                <h3>プレスリリースを配信しました</h3>
                <p>
                  日本初ジョブ型人事制度に特化した総合型クラウドサービス「JOB
                  Scope」の<a
                    href="https://prtimes.jp/main/html/rd/p/000000017.000004868.html"
                    class="detail_link"
                    target="_blank"
                    rel="noopener"
                    >プレスリリース</a
                  >が配信されました。
                  従来の評価主体の評価システムとは違い、社員自身がキャリア形成をデザインすることで、組織が競争力ある企業に変革します。
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/04/18</p></div>
              <div class="detail">
                <h3>HR EXPOへ出展、特別講演を実施いたします</h3>
                <p>
                  5/11～5/13、HRソリューション・人事業務支援サービスの展示会となるHR
                  EXPOに出展の上、日本初のジョブ型人事制度に特化した総合型クラウド(※)『JOB
                  Scope』の紹介及びセミナー講演を実施します。※人事系クラウドサービスにおいて。(2022
                  年 4月現在、株式会社CSI調べ)
                </p>
              </div>
            </div>
            <div class="news_data">
              <div class="date"><p>2022/03/17</p></div>
              <div class="detail">
                <h3>
                  人事コンサルティングアドバイザリー就任のおしらせ（安田雅彦氏）
                </h3>
                <p>
                  グローバル企業の人事領域の重要な役職を歴任されたジョブ型人事のスペシャリストである安田雅彦氏が人事コンサルティングのアドバイザリーとして就任しました。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </modal>
  </div>
</template>

<script>
export default {
  name: "DialogTemplate",
  props: {
    maxWidth: {
      type: Number,
    },
  },
  methods: {
    hide_modal(i) {
      this.$modal.hide(`modal_${i}`);
    },
  },
};
</script>

<style lang="scss">
.modal {
  &_fixed {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  &_button {
    position: fixed;
    z-index: 100;
  }
}
.vm--modal {
  overflow-y: scroll !important;
  height: 90vh !important;
  top: 5vh !important;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    // background-color: rgba(51, 51, 51, 0.3);
    border-radius: 4px;
  }
}
.news {
  .pc_flex {
    h2 {
      font-weight: bold;
      color: #080954;
    }
    .news_flex {
      .news_data {
        .date {
          color: #b4b5cb;
        }
        .detail {
          h3 {
            color: #080954;
          }
          a {
            color: #ed6d35;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .vm--modal {
    border-radius: 32px !important;
  }
  .modal {
    &_fixed {
      padding: 50px 40px 0;
    }
  }
  .news {
    .pc_flex {
      padding: 0 80px 54px;
      h2 {
        font-size: 36px;
      }
      .news_flex {
        .news_data {
          display: flex;
          margin-top: 30px;
          .date {
            padding-right: 20px;
            p {
              font-size: 16px;
              line-height: 26px;
            }
          }
          .detail {
            h3 {
              font-size: 20px;
              margin-bottom: 12px;
            }
            p {
              font-size: 16px;
              line-height: 26px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .vm--modal {
    border-radius: 16px !important;
  }
  .modal {
    &_fixed {
      padding: 23px 20px 0;
    }
  }
  .news {
    .pc_flex {
      padding: 0 20px 23px;
      h2 {
        font-size: 18px;
        margin-top: 6px;
      }
      .news_flex {
        .news_data {
          margin-top: 24px;
          .date {
            p {
              font-size: 14px;
              line-height: 32px;
            }
          }
          .detail {
            h3 {
              font-size: 16px;
              margin-bottom: 12px;
            }
            p {
              font-size: 14px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
}
</style>
