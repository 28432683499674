<template>
  <footer>
    <div class="text">
      <div class="link">
        <!-- <router-link to="/privacy">プライバシーポリシー /</router-link><router-link to="/terms">利用規約 / </router-link -->
        ><a href="https://www.defide-ix.com" target="_blank" rel="noopener">運営会社</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
footer {
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  .link a {
    color: #393838;
  }
}
@media (max-width: 767px) {
  footer {
    padding: 0 20px;
    .link {
      a {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.5;
        margin-bottom: 6px;
        &:not(&:last-of-type) {
          margin-right: 4px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  footer {
    padding: 0 40px;
    .link {
      text-align: center;
      a {
        font-size: 14px;
        display: inline-block;
        font-weight: 700;
        &:not(&:last-of-type) {
          margin-right: 4px;
        }
      }
      _:-ms-lang(x)::-ms-backdrop,
      a {
        padding-top: 5px;
      }
    }
  }
}
</style>
