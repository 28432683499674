<template>
  <main id="CX_apply" class="CX_apply" :class="{ 'macSafari': browser.isSafari }">
    <Header />
    <div>
      <v-stepper v-model="step" alt-labels>
        <v-stepper-header>
          <v-stepper-step step="1" :complete="step > 1"> お申し込み者情報 </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2" :complete="step > 2"> 会社情報 </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="step > 3"> 内容の確認 </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="4" :complete="step > 4"> 完了 </v-stepper-step>
        </v-stepper-header>
        <section ref="vApplySection" class="inner">
          <v-stepper-items>
            <v-stepper-content step="1">
              <h2>お申し込み者情報</h2>
              <div class="require_text"><p>入力必須項目</p></div>
              <div class="contact_content create">
                <ValidationObserver v-slot="{}" ref="observer1" tag="form" @submit.prevent="onSubmit1">
                  <form>
                    <div class="contact_table_container">
                      <table>
                        <tbody>
                          <tr>
                            <th><span>会社名</span></th>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="会社名"
                                v-slot="{ errors }"
                                rules="required|max:64"
                              >
                                <v-text-field
                                  v-model="data.company_info.company_name"
                                  placeholder="デフィデ株式会社"
                                  class="input"
                                  type="text"
                                  maxlength="64"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify">{{ data.company_info.company_name }}</div>
                            </td>
                          </tr>
                          <tr>
                            <th><span>管理者氏名</span></th>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="名字"
                                v-slot="{ errors }"
                                rules="required|max:15"
                              >
                                <v-text-field
                                  v-model="data.admin_user_info.last_name"
                                  placeholder="山田"
                                  class="input half half_first"
                                  type="text"
                                  maxlength="15"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">{{ data.admin_user_info.last_name }}</div>
                            </td>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="名前"
                                v-slot="{ errors }"
                                rules="required|max:15"
                              >
                                <v-text-field
                                  v-model="data.admin_user_info.first_name"
                                  placeholder="太郎"
                                  class="input half"
                                  type="text"
                                  maxlength="15"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">{{ data.admin_user_info.first_name }}</div>
                            </td>
                          </tr>
                          <tr>
                            <th><span>フリガナ</span></th>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="名字(フリガナ)"
                                v-slot="{ errors }"
                                rules="required|max:15|katakana"
                              >
                                <v-text-field
                                  v-model="data.admin_user_info.last_name_kana"
                                  placeholder="ヤマダ"
                                  class="input half half_first"
                                  type="text"
                                  maxlength="15"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">
                                {{ data.admin_user_info.last_name_kana }}
                              </div>
                            </td>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="名前(フリガナ)"
                                v-slot="{ errors }"
                                rules="required|max:15|katakana"
                              >
                                <v-text-field
                                  v-model="data.admin_user_info.first_name_kana"
                                  placeholder="タロウ"
                                  class="input half"
                                  type="text"
                                  maxlength="15"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">
                                {{ data.admin_user_info.first_name_kana }}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th><span>所属</span></th>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="所属"
                                v-slot="{ errors }"
                                rules="required|max:64"
                              >
                                <v-text-field
                                  v-model="data.admin_user_info.department"
                                  placeholder="人事部"
                                  class="input half"
                                  type="text"
                                  :error-messages="errors"
                                  maxlength="64"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">{{ data.admin_user_info.department }}</div>
                            </td>
                          </tr>
                          <tr>
                            <th><span>電話番号（管理者連絡先）</span></th>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="電話番号（管理者）"
                                v-slot="{ errors }"
                                rules="required|numeric|min:10|max:11"
                              >
                                <v-text-field
                                  v-model="data.admin_user_info.tel_no"
                                  placeholder="08012345678"
                                  class="input half"
                                  type="tel"
                                  minlength="10"
                                  maxlength="11"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">{{ data.admin_user_info.tel_no }}</div>
                            </td>
                          </tr>
                          <tr>
                            <th><span>メールアドレス</span></th>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="メールアドレス"
                                v-slot="{ errors }"
                                rules="required|email|max:128"
                              >
                                <v-text-field
                                  v-model="data.admin_user_info.email"
                                  placeholder="yamada@defide.co.jp"
                                  class="input"
                                  type="email"
                                  maxlength="128"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">{{ data.admin_user_info.email }}</div>
                            </td>
                          </tr>
                          <tr>
                            <th><span>メールアドレスを再入力</span></th>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="再入力メールアドレス"
                                v-slot="{ errors }"
                                rules="required|email|max:128|confirmed:メールアドレス"
                              >
                                <v-text-field
                                  v-model="data.admin_user_info.reEmail"
                                  placeholder="yamada@defide.co.jp"
                                  class="input"
                                  type="email"
                                  maxlength="128"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">{{ data.admin_user_info.reEmail }}</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="agree">
                        <ValidationProvider rules="checkbox" v-slot="{ errors }">
                          <v-checkbox
                            type="checkbox"
                            class="agree_checkbox"
                            :error-messages="errors"
                            v-model="checkBox"
                            value="1"
                          ></v-checkbox>
                        </ValidationProvider>
                        <div class="agree_text">
                          <router-link :to="{ name: 'Terms' }" target="_blank">利用規約</router-link>
                          および<a
                            href="https://jobscope.ai/privacy"
                            target="_blank"
                            class="contact_link"
                            rel="noopener"
                            >「プライバシーポリシー」</a
                          ><br class="pc-hide" />に同意します
                        </div>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2">
              <h2>会社情報</h2>
              <div class="require_text"><p>入力必須項目</p></div>
              <div class="contact_content create">
                <ValidationObserver v-slot="{}" ref="observer2" tag="form" @submit.prevent="onSubmit2">
                  <form>
                    <div class="contact_table_container">
                      <table>
                        <tbody>
                          <tr>
                            <th><span>本社 住所</span></th>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="郵便番号"
                                v-slot="{ errors }"
                                rules="required|numeric|min:7|max:7"
                              >
                                <v-text-field
                                  v-model="data.company_info.zip_code"
                                  placeholder="1008306"
                                  class="input zip_code"
                                  type="tel"
                                  minlength="7"
                                  maxlength="7"
                                  prefix="〒"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">{{ data.company_info.zip_code }}</div>
                            </td>
                          </tr>
                          <tr>
                            <th></th>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="住所"
                                v-slot="{ errors }"
                                rules="required|max:128"
                              >
                                <v-text-field
                                  v-model="data.company_info.address"
                                  placeholder="東京都千代田区丸の内3-3-1-4F 新東京ビル"
                                  class="input"
                                  type="text"
                                  :error-messages="errors"
                                  maxlength="128"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">{{ data.company_info.address }}</div>
                            </td>
                          </tr>
                          <tr>
                            <th><span>電話番号（代表）</span></th>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="電話番号（代表）"
                                v-slot="{ errors }"
                                rules="required|numeric|min:10|max:11"
                              >
                                <v-text-field
                                  v-model="data.company_info.tel_no"
                                  placeholder="08012345678"
                                  class="input half"
                                  type="tel"
                                  minlength="10"
                                  maxlength="11"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">{{ data.company_info.tel_no }}</div>
                            </td>
                          </tr>
                          <tr>
                            <th><span>代表者氏名</span></th>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="名字"
                                v-slot="{ errors }"
                                rules="required|max:15"
                              >
                                <v-text-field
                                  v-model="data.company_info.ceo_last_name"
                                  placeholder="山田"
                                  class="input half half_first"
                                  type="text"
                                  :error-messages="errors"
                                  maxlength="15"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">{{ data.company_info.ceo_last_name }}</div>
                            </td>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="名前"
                                v-slot="{ errors }"
                                rules="required|max:15"
                              >
                                <v-text-field
                                  v-model="data.company_info.ceo_first_name"
                                  placeholder="太郎"
                                  class="input half"
                                  type="text"
                                  :error-messages="errors"
                                  maxlength="15"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">{{ data.company_info.ceo_first_name }}</div>
                            </td>
                          </tr>
                          <tr>
                            <th><span>フリガナ</span></th>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="名字(フリガナ)"
                                v-slot="{ errors }"
                                rules="required|max:15|katakanaDash"
                              >
                                <v-text-field
                                  v-model="data.company_info.ceo_last_name_kana"
                                  placeholder="ヤマダ"
                                  class="input half half_first"
                                  type="text"
                                  :error-messages="errors"
                                  maxlength="15"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">
                                {{ data.company_info.ceo_last_name_kana }}
                              </div>
                            </td>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="名前(フリガナ)"
                                v-slot="{ errors }"
                                rules="required|max:15|katakanaDash"
                              >
                                <v-text-field
                                  v-model="data.company_info.ceo_first_name_kana"
                                  placeholder="タロウ"
                                  class="input half"
                                  type="text"
                                  :error-messages="errors"
                                  maxlength="15"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">
                                {{ data.company_info.ceo_first_name_kana }}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th><span>事業内容</span></th>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="事業内容"
                                v-slot="{ errors }"
                                rules="required|max:1000"
                              >
                                <v-text-field
                                  v-model="data.company_info.business_details"
                                  placeholder="電気製品の開発や生産、販売"
                                  class="input"
                                  type="text"
                                  maxlength="1000"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">{{ data.company_info.business_details }}</div>
                            </td>
                          </tr>
                          <tr>
                            <th><span>会社HP</span></th>
                            <td>
                              <ValidationProvider
                                v-show="!verify"
                                name="会社HP"
                                v-slot="{ errors }"
                                rules="required|max:128|url"
                              >
                                <v-text-field
                                  v-model="data.company_info.homepage"
                                  placeholder="http://cx-admin.com/"
                                  class="input"
                                  type="text"
                                  maxlength="128"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                              <div v-show="verify" class="contact_verify">{{ data.company_info.homepage }}</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </v-stepper-content>
            <v-stepper-content step="3">
              <h2>内容の確認</h2>
              <div class="contact_table_container form">
                <table>
                  <tbody>
                    <tr>
                      <th>会社名</th>
                      <td>
                        {{ data.company_info.company_name }}
                      </td>
                    </tr>
                    <tr>
                      <th>管理者氏名</th>
                      <td>{{ data.admin_user_info.last_name }} {{ data.admin_user_info.first_name }}</td>
                    </tr>
                    <tr>
                      <th>フリガナ</th>
                      <td>{{ data.admin_user_info.last_name_kana }} {{ data.admin_user_info.first_name_kana }}</td>
                    </tr>
                    <tr>
                      <th>所属</th>
                      <td>
                        {{ data.admin_user_info.department }}
                      </td>
                    </tr>
                    <tr>
                      <th>電話番号（管理者連絡先）</th>
                      <td>
                        {{ data.admin_user_info.tel_no }}
                      </td>
                    </tr>
                    <tr>
                      <th>メールアドレス</th>
                      <td>
                        {{ data.admin_user_info.email }}
                      </td>
                    </tr>
                    <tr>
                      <th>本社 住所</th>
                      <td>
                        〒{{ data.company_info.zip_code }}
                        {{ data.company_info.address }}
                      </td>
                    </tr>
                    <tr>
                      <th>電話番号（代表）</th>
                      <td>
                        {{ data.company_info.tel_no }}
                      </td>
                    </tr>
                    <tr>
                      <th>代表者氏名</th>
                      <td>{{ data.company_info.ceo_last_name }} {{ data.company_info.ceo_first_name }}</td>
                    </tr>
                    <tr>
                      <th>フリガナ</th>
                      <td>{{ data.company_info.ceo_last_name_kana }} {{ data.company_info.ceo_first_name_kana }}</td>
                    </tr>
                    <tr>
                      <th>事業内容</th>
                      <td>
                        {{ data.company_info.business_details }}
                      </td>
                    </tr>
                    <tr>
                      <th>会社HP</th>
                      <td>
                        {{ data.company_info.homepage }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-stepper-content>
            <v-stepper-content step="4">
              <h2>お申し込み完了</h2>
              <p class="completion_text">
                お申込みありがとうございました。<br />仮登録が完了いたしましたら、<br
                  class="pc-hide"
                />ご入力いただいたメールアドレスに<br class="sp-hide" />ご連絡いたします。<br class="pc-hide" />
              </p>
            </v-stepper-content>
          </v-stepper-items>

          <div class="contact_btns" v-if="step === 3">
            <v-btn
              depressed
              :loading="submitting"
              rounded
              @click="submit()"
              color="#f8753d"
              class="ok"
              ><span class="white--text">確定して申し込む</span></v-btn
            >
          </div>

          <div class="stepperMove" v-if="step < 4">
            <div class="stepperMove_back">
              <div :class="{ stepperMove_disabled: step === 1 }" @click="step--">
                <i class="icon icon-next"></i> BACK
              </div>
            </div>
            <div class="stepperMove_next">
              <div :class="{ stepperMove_disabled: step === 3 }" @click="next()">
                NEXT <i class="icon icon-next"></i>
              </div>
            </div>
          </div>
        </section>
      </v-stepper>
    </div>
  </main>
</template>

<script>
import Header from '../components/Header.vue'
import Vue from 'vue'
import { localize, extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja.json'
import { required, min, max, email, numeric, confirmed } from 'vee-validate/dist/rules'
import axios from 'axios'
import browserDetect from "vue-browser-detect-plugin";

extend('required', required)
extend('checkbox', { ...required, message: 'お申し込みには同意が必要です' })
extend('numeric', { ...numeric, message: '{_field_}は半角数字で入力してください' })
extend('min', min)
extend('max', max)
extend('confirmed', confirmed)
extend('email', {
  ...email,
  message: '正しいフォーマットで入力してください',
})
extend('url', (value) => {
  return /^https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+$/.test(value) || '正しいフォーマットで入力してください'
})
extend('katakana', (value) => {
  return /^[ァ-ヴー]+$/.test(value) || '全角カタカナで入力してください'
})
extend('katakanaDash', (value) => {
  return /^[ァ-ヴー]+$/.test(value) || '全角カタカナで入力してください'
})
localize('ja', ja)

Vue.use(browserDetect)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

export default {
  name: 'Apply',
  components: {
    Header,
  },
  data() {
    return {
      data: {
        admin_user_info: {
          last_name: '',
          first_name: '',
          last_name_kana: '',
          first_name_kana: '',
          department: '',
          tel_no: '',
          email: '',
          reEmail: '',
        },
        company_info: {
          company_name: '',
          zip_code: '',
          address: '',
          tel_no: '',
          ceo_last_name: '',
          ceo_first_name: '',
          ceo_last_name_kana: '',
          ceo_first_name_kana: '',
          business_details: '',
          homepage: '',
        },
      },
      checkBox: null,
      verify: false,
      submitting: false,
      done: false,
      step: 1,
      browser: this.$browserDetect,
      backgroundSide: 0
    }
  },
  watch: {
    step: function (value) {
      switch (value) {
        case 1:
        case 2:
          document.title = 'お申し込み | JOB Scope'
          break
        case 3:
          document.title = 'お申し込み確認 | JOB Scope'
          break
        case 4:
          document.title = 'お申し込み完了 | JOB Scope'
          break
        default:
          break
      }
      this.$router.replace({ params: { id: value } })
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    document.title = 'お申し込み | JOB Scope'
    this.handleResize()
  },
  methods: {
    async next() {
      const _this = this
      let isValid
      switch (this.step) {
        case 1:
          isValid = await _this.$refs.observer1.validate()
          _this.onSubmit1
          break
        case 2:
          isValid = await _this.$refs.observer2.validate()
          _this.onSubmit2
          break
        default:
          break
      }
      if (isValid) {
        _this.step++
      }
    },
    submit() {
      this.submitting = true
      const self = this
      let submitData = JSON.parse(JSON.stringify(self.data))
      delete submitData.admin_user_info.reEmail
      let base
      switch (location.hostname) {
        case 'hrscape.jp': //本番環境(未対応)
        case 'www.hrscape.jp':
        case 'jobscope.ai':
        case 'www.jobscope.ai':
        case 'd2pbie4k5e8wx6.cloudfront.net':
          base = 'https://console.jobscope.ai/api/ad/signup'
          break
        case 'd1g1s3y0z05pku.cloudfront.net': //DEV環境
        case 'dev.jobscope.ai':
          base = 'https://dev.console.jobscope.ai/api/ad/signup'
          break
        case 'd1a6pp170hn3cr.cloudfront.net': //STG環境
        case 'stg.jobscope.ai':
          base = 'https://stg.console.jobscope.ai/api/ad/signup'
          break
        case 'jobscope.online':
          base = 'https://console.jobscope.online/api/ad/signup' //DEMO環境
          break
        default:
          base = 'https://dev.console.jobscope.ai/api/ad/signup' //ローカルもDEV環境
          break
      }
      const instance = axios.create({
        baseURL: base,
        timeout: 30000,
      })
      instance
        .post('', submitData)
        .then(function () {
          self.step++
          self.submitting = false
        })
        .catch(function (res) {
          alert(`お申し込み情報の送信に失敗しました。お手数ですが時間を置いて再度お試しください。\n${res}`)
          self.submitting = false
        })
    },
    handleResize() {
      const imageWidth = 143 // Background image's width
      // Calculate sides of screen except main body
      let backgroundSide = (((window.innerWidth - this.$refs.vApplySection.clientWidth - 8) / 2) - imageWidth) / 2
      backgroundSide < 0 && (backgroundSide = 0)
      // Set left and right CSS for var --calcPosition
      document.querySelector('#CX_apply').style.setProperty('--calcPosition', backgroundSide + 'px')
    }
  },
}
</script>

<style lang="scss" scoped>
.CX_apply {
  position: relative;
  header {
    padding-left: 16px;
    box-shadow: 0 4px 12px 0 rgb(0 0 0 / 6%);
    position: fixed;
    width: 100%;
    z-index: 10;
  }
  color: #393838;
  background-color: #fbf8f1;

  h2 {
    text-align: center;
    position: relative;
    font-weight: 700;
    font-family: 'Noto Sans JP', sans-serif;
  }
  .require_text {
    display: flex;
    justify-content: flex-end;
    max-width: 640px;
    margin: 0 auto 22px;
    p {
      color: #e65d5d;
      font-size: 12px;
      letter-spacing: 0.6px;
      position: relative;
      &::after {
        content: url('../assets/img/common/ico_required.svg');
        position: absolute;
        display: block;
        top: -3px;
        left: -24px;
      }
    }
  }
  .inner {
    background-color: #fff;
    border-top: 1px solid #eee;
  }
  .ok {
    width: 200px;
    height: 40px;
    line-height: 40px;
    color: white;
  }
  .agree {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    margin-top: 30px;
    .agree_checkbox {
      margin-right: 10px;
      position: relative;
    }
    &_text {
      font-size: 12px;
      line-height: 1.5;
      a {
        color: #6c86ce;
      }
    }
  }
}

.CX_apply:before {
  content: "";
  position: absolute;
  width: 143px;
  height: 260px;
  bottom: 40px;
  left: var(--calcPosition);
  background-image: url("../assets/img/pc/apply_background_01.png");
}
.CX_apply:after {
  content: "";
  position: absolute;
  width: 143px;
  height: 260px;
  bottom: 40px;
  right: var(--calcPosition);
  background-image: url("../assets/img/pc/apply_background_02.png");
}

@media (max-width: 767px) {
  .CX_apply {
    & > div {
      padding: 60px 8px 0;
    }
    .sp-hide {
      display: none;
    }
    h2 {
      font-size: 20px;
      line-height: 33px;
      letter-spacing: 1.2px;
      margin-bottom: 22px;
    }
    .inner {
      padding: 20px 20px 0;
      .contact_content {
        margin: 0 auto;
      }
      .contact_title {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 28px;
        text-align: center;
        white-space: nowrap;
        @media (min-width: 600px) {
          br {
            display: none;
          }
        }
      }

      table {
        border: 0;
        width: 100%;
        tr {
          display: block;
          margin-bottom: 16px;
        }
        th {
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          width: 100%;
          line-height: 21px;
          color: #666;
          span {
            display: inline-block;
            position: relative;
          }
        }
        th,
        td {
          display: block;
        }
        td {
          position: relative;
        }
      }
      .input,
      .textarea {
        width: 100%;
      }
      .input {
        height: 100%;
        margin-top: 0;
      }
      .contact_btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 50px;
        margin: 30px auto;
        > .v-btn {
          font-size: 16px;
          text-align: center;
          font-weight: 500;
          letter-spacing: 0.8px;
        }
      }
    }
  }
  .create {
    th {
      span {
        &::after {
          top: 1px;
          right: -26px;
        }
      }
    }
  }
  .form {
    tr {
      margin-bottom: 20px;
      min-height: 40px;
      th {
        margin-bottom: 8px;
      }
      th,
      td {
        line-height: 21px !important;
      }
      td {
        color: #333;
      }
    }
  }
  .completion_text {
    text-align: center;
    line-height: 24px;
    letter-spacing: 0.8px;
  }
}

@media (min-width: 768px) {
  .CX_apply {
    position: relative;
    overflow: hidden;
    // &::after {
    //   position: absolute;
    //   content: url("../assets/img/pc/apply_left.png");
    //   left: calc((100vw - 1080px) / 2 - 124px);
    //   bottom: -70px;
    //   @media (max-width: 1120px) {
    //     left: -104px;
    //   }
    // }
    .pc-hide {
      display: none;
    }
    & > div {
      padding: 60px 0 0;
    }
    h2 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 22px;

      .v-btn {
        position: absolute;
        display: block;
        left: 0;
        width: 32px;
        height: 32px;
        min-width: auto;
        background-color: transparent;
        div {
          height: 100%;
        }
        div::after {
          content: '';
          position: absolute;
          display: block;
          width: 22px;
          height: 22px;
          border-top: 4px solid #a7adbb;
          border-left: 4px solid #a7adbb;
          transform: rotate(-45deg);
          top: -10px;
          left: -5px;
        }
      }
      .v-btn__content {
        height: 100%;
      }
    }
    .inner {
      margin: 0 auto;
      padding: 32px 220px 0;
      min-height: calc(100vh - 166px);
      form,
      .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0 auto;
      }
      .form {
        tr {
          min-height: 40px;
          margin-bottom: 6px !important;
        }
        th {
          flex-shrink: 0;
        }
        th,
        td {
          line-height: 21px;
          display: flex;
          align-items: center;
          word-break: break-all;
        }
      }
      .contact_table_container {
        width: 100%;
        min-height: 390px;
        table {
          width: 100%;
        }
      }
      @media (max-width: 1120px) {
        max-width: 1080px;
        padding: 32px 0 0;
        min-height: calc(100vh - 102px);
        form,
        .form {
          width: 640px;
        }
      }

      .contact_title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 45px;
        text-align: center;
      }

      .input.half {
        width: 205px;
        &.half_first {
          margin-right: 10px;
        }
      }

      table {
        border: 0;
        tr {
          display: flex;
          &:not(:last-of-type) {
            margin-bottom: 16px;
          }
        }
        th {
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          width: 220px;
          color: #666;
          span {
            display: block;
            position: relative;
            line-height: 24px;
            &::after {
              top: 3px;
              right: 12px;
            }
          }
        }
        td {
          height: 40px;
          font-size: 14px;
          flex-grow: 1;
        }
      }
      .completion_text {
        line-height: 32px;
        letter-spacing: 0.8px;
        text-align: center;
        margin-top: 20px;
        font-weight: 500;
      }
      .contact_btns {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 60px auto;
        button {
          font-size: 16px;
        }
        .cancel {
          margin-right: 30px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.CX_apply {
  input,
  textarea {
    color: #333;
    caret-color: #6c86ce;
    &::placeholder {
      color: #ccc;
    }
  }
  .v-text-field__prefix {
    color: #333;
  }
  .v-stepper {
    z-index: 1;
    max-width: 1080px;
    margin: 0 auto;
    box-shadow: none !important;
    &__content {
      padding: 0 !important;
    }
    &__header {
      width: 540px;
      margin: auto;
      box-shadow: unset;
      padding-top: 4px;
      .v-divider {
        border-color: #a5afbb !important;
        border-width: 1px;
        margin: 37px -67.5px 0 !important;
      }
      .v-stepper {
        &__label {
          font-size: 12px;
          letter-spacing: 0.6px;
          font-weight: 500;
          color: #a5afbb !important;
          display: flex !important;
        }
        &__step {
          padding: 24px 0 21px;
          &__step {
            width: 30px;
            height: 30px;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 15px;
            background-color: unset !important;
            border: 2px solid #a5afbb;
            color: #a5afbb !important;
          }
          &--active,
          &--complete {
            .v-stepper__label {
              color: #9fc24d !important;
            }
            span {
              color: white !important;
              box-shadow: 0 0 0 5px white, 0 0 0 6px #9fc24d;
              border-color: #9fc24d !important;
              background-color: #9fc24d !important;
            }
          }
        }
      }
    }
    &__items {
    }
    .stepperMove {
      display: flex;
      font-size: 20px;
      color: #55bfb4;
      user-select: none;
      height: fit-content;
      -webkit-user-select: none;
      width: 220px;
      margin: 90px auto 0;
      justify-content: space-between;
      margin-bottom: 60px;
      &_back,
      &_next {
        div {
          cursor: pointer;
          display: flex;
        }
        .icon {
          font-family: 'icons' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          font-size: 24px;
          position: relative;
          &-next::before {
            display: block;
            content: url('../assets/img/common/arrow.svg');
            transform: scale(1.5);
          }
        }
      }
      &_back {
        .icon {
          top: -3px;
        }
        .icon-next {
          display: inline-block;
          transform: rotate(180deg);
        }
      }
      &_next {
        .icon {
          top: 0;
        }
      }
      &_disabled {
        .icon {
          &-next::before {
            content: url('../assets/img/common/arrow_disabled.svg');
          }
        }
      }
      &_back .icon {
        right: 10px;
      }
      &_next .icon {
        left: 10px;
      }
      &_disabled {
        pointer-events: none;
        color: #cccccc;
      }
    }
  }
  .agree {
    .v-input__slot {
      margin-bottom: 0;
    }
    .primary--text {
      color: #6c86ce !important;
      caret-color: #6c86ce !important;
    }
    .v-messages__wrapper {
      width: 168px;
    }
    .v-messages {
      white-space: nowrap;
      top: 30px;
      position: absolute;
    }
  }
  .create {
    .v-input {
      margin-top: 0;
      padding-top: 0;
      &__slot {
        color: #6c86ce;
      }
      &.error--text .v-input__slot {
        color: #ff5252;
      }
    }
    .contact_link {
      color: #ff6600;
    }
    th {
      span {
        &::after {
          content: url('../assets/img/common/ico_required.svg');
          position: absolute;
          display: block;
        }
      }
    }
    .error--text .v-messages__message {
      color: #ff5252 !important;
    }
    .zip_code input {
      padding-left: 10px;
    }
  }
}
@media (max-width: 767px) {
  .CX_apply {
    .create {
      input {
        padding: 0;
        height: 32px;
      }
      textarea {
        line-height: 24px !important;
      }
    }
    .v-stepper {
      &__header {
        max-width: 100%;
        .v-divider {
          margin: 37px calc((25% - 40px) / -2) 0 !important;
        }
        .v-stepper__step {
          flex-basis: 25% !important;
        }
        .v-stepper__label {
          white-space: nowrap;
        }
      }
      .stepperMove {
        margin: 30px auto 60px;
      }
    }
    .agree {
      .v-messages {
        top: 36px;
      }
    }
  }
}
@media (min-width: 768px) {
  .CX_apply {
    .v-text-field__prefix {
      color: #333;
      position: relative;
      top: -2px;
    }
    .v-input {
      margin-top: 0;
      padding: 0;
    }
    input {
      padding-top: 0 !important;
      padding-bottom: 4px !important;
      height: 28px;
      font-size: 14px;
    }
  }
}
_:lang(x) + _:-webkit-full-screen-document,
.stepperMove_back .icon {
  top: 0 !important;
}
_:lang(x) + _:-webkit-full-screen-document,
.stepperMove_next .icon {
  top: -3px !important;
}
.macSafari {
  .v-stepper__step {
    .v-stepper__step__step {
      padding-bottom: 2px;
    }
  }
}
</style>
