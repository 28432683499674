var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"CX_contact common-style-1"},[_c('Header'),_c('div',[_c('h2',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.done),expression:"!done"}],attrs:{"depressed":""},on:{"click":function($event){return _vm.back()}}},[_c('div')]),_vm._v("お問い合わせ"+_vm._s(_vm.done ? '完了' : '')+" ")],1),_c('section',{staticClass:"inner"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.done),expression:"!done"}],staticClass:"contact_content",class:{ create: !_vm.verify, confirm: _vm.verify }},[_c('div',{staticClass:"contact_title"},[(_vm.verify)?[_vm._v("内容をよくお確かめの上、"),_c('br',{staticClass:"pc-hide"}),_vm._v("「お問い合わせ」ボタンを押してください。")]:_vm._e(),(!_vm.verify)?[_vm._v("以下の項目を入力し、内容をよくお確かめの上、"),_c('br',{staticClass:"pc-hide"}),_vm._v("「同意して確認」ボタンを押してください。")]:_vm._e()],2),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('div',{staticClass:"contact_table_container"},[_c('table',[_c('tbody',[_c('tr',{staticClass:"double"},[_c('th',[_c('span',[_vm._v("氏名")])]),_c('td',[_c('ValidationProvider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.verify),expression:"!verify"}],attrs:{"name":"名字","rules":"required|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input half_first",attrs:{"placeholder":"山田","type":"text","maxlength":"15","error-messages":errors},model:{value:(_vm.data.last_name),callback:function ($$v) {_vm.$set(_vm.data, "last_name", $$v)},expression:"data.last_name"}})]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.verify),expression:"verify"}],staticClass:"contact_verify"},[_vm._v(_vm._s(_vm.data.last_name))])],1),_c('td',[_c('ValidationProvider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.verify),expression:"!verify"}],attrs:{"name":"名前","rules":"required|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"placeholder":"太郎","type":"text","maxlength":"15","error-messages":errors},model:{value:(_vm.data.first_name),callback:function ($$v) {_vm.$set(_vm.data, "first_name", $$v)},expression:"data.first_name"}})]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.verify),expression:"verify"}],staticClass:"contact_verify"},[_vm._v(_vm._s(_vm.data.first_name))])],1)]),_c('tr',[_c('th',[_c('span',[_vm._v("会社名")])]),_c('td',[_c('ValidationProvider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.verify),expression:"!verify"}],attrs:{"name":"会社名","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"placeholder":"デフィデ株式会社","type":"text","maxlength":"64","error-messages":errors},model:{value:(_vm.data.companyName),callback:function ($$v) {_vm.$set(_vm.data, "companyName", $$v)},expression:"data.companyName"}})]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.verify),expression:"verify"}]},[_vm._v(_vm._s(_vm.data.companyName))])],1)]),_c('tr',[_c('th',[_c('span',[_vm._v("部署名")])]),_c('td',[_c('ValidationProvider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.verify),expression:"!verify"}],attrs:{"name":"部署名","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input half",attrs:{"placeholder":"人事部","type":"text","error-messages":errors,"maxlength":"64"},model:{value:(_vm.data.departmentName),callback:function ($$v) {_vm.$set(_vm.data, "departmentName", $$v)},expression:"data.departmentName"}})]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.verify),expression:"verify"}],staticClass:"contact_verify"},[_vm._v(_vm._s(_vm.data.departmentName))])],1)]),_c('tr',[_c('th',[_c('span',[_vm._v("電話番号")])]),_c('td',[_c('ValidationProvider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.verify),expression:"!verify"}],attrs:{"name":"電話番号","rules":"required|numeric|min:10|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input half",attrs:{"placeholder":"08012345678","type":"tel","minlength":"10","maxlength":"11","error-messages":errors,"auto-grow":""},model:{value:(_vm.data.telephoneNumber),callback:function ($$v) {_vm.$set(_vm.data, "telephoneNumber", $$v)},expression:"data.telephoneNumber"}})]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.verify),expression:"verify"}],staticClass:"contact_verify"},[_vm._v(_vm._s(_vm.data.telephoneNumber))])],1)]),_c('tr',[_c('th',[_c('span',[_vm._v("メールアドレス")])]),_c('td',[_c('ValidationProvider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.verify),expression:"!verify"}],attrs:{"name":"メールアドレス","rules":"required|email|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"placeholder":"半角英数","type":"email","required":"","maxlength":"100","error-messages":errors},model:{value:(_vm.data.emailAddress),callback:function ($$v) {_vm.$set(_vm.data, "emailAddress", $$v)},expression:"data.emailAddress"}})]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.verify),expression:"verify"}],staticClass:"contact_verify"},[_vm._v(_vm._s(_vm.data.emailAddress))])],1)]),_c('tr',[_c('th',[_c('span',[_vm._v("お問い合わせ内容")])]),_c('td',[_c('ValidationProvider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.verify),expression:"!verify"}],attrs:{"name":"お問い合わせ内容","rules":"required|max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"textarea",attrs:{"placeholder":"1000文字以内","rows":"1","row-height":"28","auto-grow":"","no-resize":"","required":"","maxlength":"1000","error-messages":errors},model:{value:(_vm.data.content),callback:function ($$v) {_vm.$set(_vm.data, "content", $$v)},expression:"data.content"}})]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.verify),expression:"verify"}],staticClass:"contact_verify mb"},[_vm._v(_vm._s(_vm.data.content))])],1)])])])]),(!_vm.verify)?_c('div',{staticClass:"contact_text"},[_vm._v(" お問い合わせには "),_c('a',{staticClass:"contact_link",attrs:{"href":"https://www.defide-ix.com/privacyPolicy/","target":"_blank","rel":"noopener"}},[_vm._v("「プライバシーポリシー」")]),_c('br',{staticClass:"pc-hide"}),_vm._v("の同意が必要です。 ")]):_vm._e(),_c('div',{staticClass:"contact_btns"},[_c('v-btn',{staticClass:"cancel",attrs:{"depressed":"","disabled":_vm.submitting,"outlined":"","color":"#080954"},on:{"click":function($event){return _vm.back()}}},[_vm._v(_vm._s(_vm.verify ? '戻る' : 'キャンセル'))]),_c('v-btn',{staticClass:"ok",attrs:{"disabled":invalid,"depressed":"","loading":_vm.submitting,"color":"#f97c40"},on:{"click":function($event){return _vm.next()}}},[_vm._v(_vm._s(_vm.verify ? 'お問い合わせ' : '同意して確認'))])],1)])]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.done),expression:"done"}],staticClass:"completion_content"},[_vm._m(0),_c('v-btn',{staticClass:"top",attrs:{"depressed":"","to":{ name: 'Main', params: { innerLink: true } },"rounded":"","color":"#9ad574"}},[_vm._v("トップページへ戻る")])],1)])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"completion_title"},[_c('h3',[_vm._v("ありがとうございました。"),_c('br'),_vm._v("お問い合わせを受け付けました。")]),_c('p',[_c('span',[_vm._v("折り返し、担当者よりご連絡いたしますので、 "),_c('br'),_vm._v("恐れ入りますが、しばらくお待ちください。")]),_c('br'),_c('br'),_vm._v("なお、ご入力いただいたメールアドレス宛に受付完了メールを配信しております。 "),_c('br'),_vm._v("完了メールが届かない場合、処理が正常に行われていない可能性があります。 "),_c('br'),_vm._v("大変お手数ですが、再度お問い合わせの手続きをお願い致します。 ")])])}]

export { render, staticRenderFns }