<template>
  <header>
    <router-link :to="{ name: 'Main' }"
      ><img src="../assets/img/common/h1.svg" alt="JOB Scope"
    /></router-link>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
header {
  display: flex;
  align-items: center;
  padding-left: 40px;
  background-color: white;
  height: 60px;
  img {
    width: 111px;
    height: 15.5px;
  }
}
@media (max-width: 767px) {
  header {
    padding-left: 20px;
  }
}
</style>
