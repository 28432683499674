<template>
  <div>
    <tabs
      :options="{ useUrlFragment: false, defaultTabHash: 'tab1' }"
      @changed="tabChanged"
    >
      <tab id="tab1" name="CM" aria-label="CM">
        <iframe
          v-if="youtubeFlag || isActive === 'tab1'"
          ref="youtube"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/JkuJvjgoqRE"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          playsinline
          loading="lazy"
        ></iframe
      ></tab>
      <tab id="tab2" name="プロモーション動画" aria-label="プロモーション動画">
        <iframe
          v-if="isActive === 'tab2'"
          ref="youtube2"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/O7k-nLaN0Fo"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          playsinline
          loading="lazy"
        ></iframe
      ></tab>
      <tab id="tab3" name="サービス紹介" aria-label="サービス紹介"
        ><iframe
          v-if="isActive === 'tab3'"
          ref="youtube3"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/8nhOff9eEMk"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          playsinline
          loading="lazy"
        ></iframe>
      </tab>
    </tabs>
  </div>
</template>
<script>
export default {
  name: "PromotionTabs",
  components: {},
  data() {
    return {
      isActive: "tab1",
      youtubeFlag: false,
    };
  },
  methods: {
    tabChanged(selectedTab) {
      this.isActive = selectedTab.tab.id;
    },
  },
  mounted() {
    setTimeout(() => {
      this.youtubeFlag = true;
    }, 1000);
  },
};
</script>

<style lang="scss">
.tabs-component-tabs {
  border: 0;
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  margin-bottom: -1px;
}

.tabs-component-tab {
  width: 33%;
  margin-right: 4px;
  color: #cccccc;
  background-color: #f1f1f1;
  text-align: center;
  &:last-child {
    margin-right: 0;
  }
}
.tabs-component-tab.is-active {
  color: #080954;
  background-color: #e0e5f1;
}

.tabs-component-tab-a {
  display: inline-block;
  align-items: center;
  width: 100%;
  height: 100%;
  letter-spacing: 0.64px;
}

.tabs-component-panels {
  background-color: #e0e5f1;
  iframe {
    display: block;
    margin: 0 auto;
  }
}

@media (max-width: 520px) {
  .tabs-component-tabs {
    width: 335px;
  }

  .tabs-component-panels {
    width: 335px;
    height: 190px;
    iframe {
      width: 328px;
      height: 185px;
    }
  }
}

@media (max-width: 1023px) {
  .tabs-component-tabs {
    width: 90%;
    margin: 0 auto;
    border-radius: 6px 0;
  }

  .tabs-component-tab {
    height: 36px;
    border-radius: 6px 6px 0 0;
  }

  .tabs-component-tab-a {
    font-size: 11px;
    line-height: 36px;
  }

  .tabs-component-panels {
    width: 90%;
    margin: 0 auto;
    iframe {
      width: 98%;
      aspect-ratio: 16 / 9;
      padding: 3px;
    }
  }
}

@media (min-width: 1024px) {
  .tabs-component-tabs {
    border-radius: 6px 0;
  }

  .tabs-component-tab {
    height: 50px;
    border-radius: 6px 6px 0 0;
    line-height: 25px;
  }

  .tabs-component-tab-a {
    line-height: 50px;
  }

  .tabs-component-panels {
    height: 327px;
    width: 572px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
