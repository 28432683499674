<template>
  <div class="cx_lp">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="scss">
@import "./assets/css/reset.css";
@font-face {
  src: url("../public/fonts/NotoSansCJKjp-Regular.woff") format("woff");
  font-family: "Noto Sans JP";
  font-style: normal;
  font-display: swap;
}
@font-face {
  src: url("../public/fonts/NotoSansJP-Bold.otf") format("otf");
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}
body {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(51, 51, 51, 0.3);
    border-radius: 4px;
  }
}
.cx_lp {
  font-family: "Noto Sans JP", sans-serif;
  @media (max-width: 1023px) {
    .sp_hide {
      display: none !important;
    }
  }
  @media (min-width: 1024px) {
    .pc_hide {
      display: none !important;
    }
  }
  .common-style-1 {
    background-color: #e9f6e0;
    color: #393838;
    font-weight: 500;
    h2 {
      color: #080954;
      text-align: center;
      position: relative;
      font-weight: 700;
    }
    .inner {
      background-color: #fff;
    }
    @media (max-width: 767px) {
      & > div {
        padding: 0 10px 30px;
      }
      h2 {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 1.2px;
        margin-top: 30px;

        .v-btn {
          position: absolute;
          display: flex;
          left: 10px;
          width: 33px;
          height: 33px;
          background-color: transparent;
          min-width: auto;
          div {
            height: 100%;
          }
          div::after {
            content: "";
            position: absolute;
            display: block;
            width: 18px;
            height: 18px;
            border-top: 3px solid #a7adbb;
            border-left: 3px solid #a7adbb;
            transform: rotate(-45deg);
            top: -9px;
            left: -4px;
          }
        }
      }
      .inner {
        margin-top: 34px;
        padding: 38px 20px;
        border-radius: 7px;
        h3,
        p,
        li {
          font-size: 12px;
          line-height: 20px;
        }
        h3 {
          font-weight: 700;
          color: #080954;
          margin-bottom: 5px;
        }
        p,
        ul {
          color: #393838;
        }

        > div {
          margin-top: 24px;
        }

        .top {
          padding-bottom: 25px;
          border-bottom: 3px solid #e0e5f1;
          margin-top: 0;
          a {
            color: #4457b5;
          }
        }

        .first {
          margin-top: 28px;
        }
        ul.ml {
          margin-left: 12px;
          &.no li {
            margin-left: 0;
          }
        }

        .list {
          margin-left: 12px;
          li {
            position: relative;
            &::before {
              position: absolute;
              content: "";
              width: 2px;
              height: 2px;
              display: inline-block;
              background-color: #393838;
              border-radius: 50%;
              top: 12px;
              left: -6px;
            }
          }
        }

        .bottom {
          margin-top: 40px;
          h3 {
            color: #393838;
            font-weight: 500;
            margin-bottom: 0;
            font-size: 12px;
          }
          p {
            margin-left: 0;
          }
        }
      }
    }
    @media (min-width: 768px) {
      & > div {
        padding: 45px 10px 50px;
      }
      h2 {
        font-size: 32px;
        letter-spacing: 4.8px;
        max-width: 960px;
        margin: 0 auto;

        .v-btn {
          position: absolute;
          display: flex;
          left: 0;
          width: 32px;
          height: 32px;
          min-width: auto;
          background-color: transparent;
          div {
            height: 100%;
          }
          div::after {
            content: "";
            position: absolute;
            display: block;
            width: 22px;
            height: 22px;
            border-top: 4px solid #a7adbb;
            border-left: 4px solid #a7adbb;
            transform: rotate(-45deg);
            top: -10px;
            left: -5px;
          }
        }
      }
      .inner {
        border-radius: 10px;
        max-width: 960px;
        margin: 50px auto 0;
        background-color: #fff;
        padding: 50px;
        h3 {
          font-size: 16px;
          font-weight: 700;
          color: #080954;
          margin-bottom: 24px;
        }
        p,
        li {
          color: #393838;
          margin-left: 32px;
          font-size: 14px;
          line-height: 26px;
        }
        > div {
          margin-top: 40px;
        }
        .top {
          border-bottom: 3px solid #e0e5f1;
          margin-top: 0;
          padding-bottom: 30px;
          a {
            color: #4457b5;
          }
          p {
            margin-left: 0;
          }
        }
        ul.ml {
          margin-left: 14px;
          &.no li {
            margin-left: 0;
          }
        }

        .list {
          li {
            position: relative;
            &::before {
              position: absolute;
              content: "";
              width: 3px;
              height: 3px;
              display: inline-block;
              background-color: #393838;
              border-radius: 50%;
              top: 12px;
              left: -10px;
            }
          }
        }

        .bottom {
          margin-top: 60px;
          h3 {
            color: #393838;
            font-weight: 500;
            margin-bottom: 0;
            font-size: 14px;
          }
          p {
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>
