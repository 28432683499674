<template>
  <div class="SpMenuAccordion" v-cloak>
    <button
      class="SpMenuAccordion_btn"
      type="button"
      :class="{ open: isOpened }"
      @click="accordionToggle()"
    >
      <slot name="title"></slot>
    </button>
    <transition
      name="SpMenuAccordion"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div
        class="SpMenuAccordion_body"
        :class="{ open: isOpened }"
        v-if="isOpened"
      >
        <slot name="body"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SpMenuAccordion",
  props: {
    menuOpenFlg: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpened: false,
  }),
  methods: {
    accordionToggle() {
      this.isOpened = !this.isOpened;
      this.$emit("toggleAccordion");
    },
    beforeEnter: function(el) {
      el.style.height = "0";
    },
    enter: function(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave: function(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave: function(el) {
      el.style.height = "0";
    },
  },
  watch: {
    menuOpenFlg(to) {
      if (to === false && this.isOpened === true) {
        this.accordionToggle();
      }
    },
  },
};
</script>

<style lang="scss">
.SpMenuAccordion {
  &_btn {
    width: 100%;
    &:after {
      content: url("../assets/img/common/MainHeader_expand.svg");
      position: absolute;
      top: 2px;
      width: 22px;
      height: 22px;
      right: 0;
      transition: all 0.2s ease-in;
      transform-origin: center;
    }
    &.open {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  &_body {
    overflow: hidden;
    transition: height 0.4s ease-in-out;
  }
  &-enter-active {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: SpMenuAccordion--anime__opend;
  }
  &-leave-active {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: SpMenuAccordion--anime__closed;
  }
}

@keyframes SpMenuAccordion--anime__opend {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes SpMenuAccordion--anime__closed {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
