<template>
  <header class="MainHeader">
    <div class="MainHeader_inner">
      <div class="MainHeader_logo">
        <router-link :to="{ name: 'Main' }"
          ><img
            src="../assets/img/common/h1.svg"
            alt="JOB Scope｜日本の人事に最適化したジョブ型人事クラウドサービス"
        /></router-link>
      </div>
      <div class="MainHeader_right">
        <ul class="MainHeader_links">
          <li class="has-child">
            <a href="javascript:void(0)">
              サービス内容
            </a>
            <ul>
              <li>
                <a href="https://jobscope.ai/mission/" target="_blank"
                  >JOB Scopeとは</a
                >
              </li>
              <li>
                <a
                  href="https://offers.jobscope.ai/job-scope-b-movie04"
                  target="_blank"
                  >サービス説明動画</a
                >
              </li>
              <li>
                <a href="https://jobscope.ai/mission/#howToUse" target="_blank"
                  >サービスご利用時の流れ</a
                >
              </li>
              <li>
                <a href="https://jobscope.ai/mission/#QA" target="_blank"
                  >Q&A</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a href="https://jobscope.ai/feature/" target="_brank">
              機能紹介
            </a>
          </li>
          <!-- <li>
            <a href="">
              導入事例
            </a>
          </li> -->
          <li class="has-child">
            <a href="javascript:void(0)"> セミナー/イベント </a>
            <ul>
              <li>
                <a href="https://offers.jobscope.ai/#movie" target="_blank"
                  >アーカイブ視聴</a
                >
              </li>
            </ul>
          </li>
          <li class="has-child">
            <a href="javascript:void(0)"> お役立ち情報 </a>
            <ul class="has-child_inner">
              <ul class="has-child_column">
                <li class="has-title title">
                  経営層・人事責任者向けコラム
                  <ul>
                    <li>
                      <a
                        href="https://marketing.jobscope.ai/organization-transformation"
                        target="_blank"
                        >中小企業の競争力を強化する組織改革／人的資本コラム一覧</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://marketing.jobscope.ai/personnel-system"
                        target="_blank"
                        >変化に打ち勝つための組織改革／ジョブ型人事制度コラム一覧</a
                      >
                    </li>
                  </ul>
                </li>
                <!-- <li class="title">人事担当者向けコラム</li> -->
                <li class="has-title title">
                  ブログ
                  <ul>
                    <li>
                      <a href="https://blog.jobscope.ai/" target="_brank"
                        >人的資本経営・ジョブ型人事・組織戦略に関するBLOG</a
                      >
                    </li>
                  </ul>
                </li>
              </ul>
              <ul class="has-child_column">
                <li class="has-title title">
                  ホワイトペーパー
                  <ul>
                    <li>
                      <a
                        href="https://offers.jobscope.ai/whitepaperdownload"
                        target="_brank"
                        >日本企業に求められている組織強化と人材育成のための人事制度は？</a
                      >
                    </li>
                  </ul>
                </li>
                <li class="has-title title">
                  ツール
                  <ul>
                    <li>
                      <a
                        href="https://marketing.jobscope.ai/salary-analysis"
                        target="_brank"
                        >AI給与分析</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://marketing.jobscope.ai/icd-reference-service"
                        target="_brank"
                        >iCDデータ参照サービス</a
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </ul>
          </li>
        </ul>
        <v-btn
          class="MainHeader_btn_apply"
          depressed
          link
          color="#F97C40"
          href="https://offers.jobscope.ai/contact"
          target="_blank"
          >デモ/お問い合わせ</v-btn
        >
      </div>
      <div
        class="MainHeader_Humberger"
        @click="menuOpen"
        :class="{ open: menuOpenFlg }"
      >
        <div class="MainHeader_Humberger_line"></div>
      </div>
      <div class="MainHeader_spMenu_wrapper">
        <nav class="MainHeader_spMenu">
          <ul ref="ul">
            <li>
              <SpMenuAccordion
                :menuOpenFlg="menuOpenFlg"
                @toggleAccordion="getNavHeight"
              >
                <div slot="title">サービス内容</div>
                <ul slot="body">
                  <li>
                    <a href="https://jobscope.ai/mission/" target="_blank">
                      JOB Scopeとは
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://offers.jobscope.ai/job-scope-b-movie04"
                      target="_blank"
                      >サービス説明動画</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://jobscope.ai/mission/#howToUse"
                      target="_blank"
                      >サービスご利用時の流れ</a
                    >
                  </li>
                  <li>
                    <a href="https://jobscope.ai/mission/#QA" target="_blank"
                      >Q&A</a
                    >
                  </li>
                </ul>
              </SpMenuAccordion>
            </li>
            <li>
              <a href="https://jobscope.ai/feature/" target="_brank">
                機能紹介
              </a>
            </li>
            <!-- <li>
              <a href="">
                導入事例
              </a>
            </li> -->
            <li>
              <SpMenuAccordion
                :menuOpenFlg="menuOpenFlg"
                @toggleAccordion="getNavHeight"
              >
                <div slot="title">セミナー/イベント</div>
                <ul slot="body">
                  <li>
                    <a href="https://offers.jobscope.ai/#movie" target="_blank"
                      >アーカイブ視聴</a
                    >
                  </li>
                </ul>
              </SpMenuAccordion>
            </li>
            <li>
              <SpMenuAccordion
                :menuOpenFlg="menuOpenFlg"
                @toggleAccordion="getNavHeight"
              >
                <div slot="title">お役立ち情報</div>
                <ul slot="body">
                  <li class="has-title">
                    経営層・人事責任者向けコラム
                    <ul>
                      <li>
                        <a
                          href="https://marketing.jobscope.ai/organization-transformation"
                          target="_blank"
                          >中小企業の競争力を強化する組織改革／人的資本コラム一覧</a
                        >
                      </li>
                      <li>
                        <a
                          href="https://marketing.jobscope.ai/personnel-system"
                          target="_blank"
                          >変化に打ち勝つための組織改革／ジョブ型人事制度コラム一覧</a
                        >
                      </li>
                    </ul>
                  </li>
                  <!-- <li class="has-title">
                    人事担当者向けコラム
                    <ul>
                      <li>
                        <a href=""></a>
                      </li>
                    </ul>
                  </li> -->
                  <li class="has-title">
                    ブログ
                    <ul>
                      <li>
                        <a href="https://blog.jobscope.ai/" target="_brank"
                          >人的資本経営・ジョブ型人事・組織戦略に関するBLOG</a
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="has-title">
                    ホワイトペーパー
                    <ul>
                      <li>
                        <a
                          href="https://offers.jobscope.ai/whitepaperdownload"
                          target="_brank"
                          >日本企業に求められている組織強化と人材育成のための人事制度は？</a
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="has-title title">
                    ツール
                    <ul>
                      <li>
                        <a
                          href="https://marketing.jobscope.ai/salary-analysis"
                          target="_brank"
                          >AI給与分析</a
                        >
                      </li>
                      <li>
                        <a
                          href="https://marketing.jobscope.ai/icd-reference-service"
                          target="_brank"
                          >iCDデータ参照サービス</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </SpMenuAccordion>
            </li>
          </ul>
          <div class="btn_wrapper">
            <v-btn
              class="MainHeader_btn_apply"
              depressed
              link
              color="#F97C40"
              href="https://offers.jobscope.ai/contact"
              target="_blank"
              >デモ/お問い合わせ</v-btn
            >
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import SpMenuAccordion from "./SpMenuAccordion";

export default {
  name: "MainHeader",
  data: () => ({
    menuOpenFlg: false,
    noPreventScroll: false,
  }),
  components: {
    SpMenuAccordion,
  },
  methods: {
    noScroll(event) {
      if (!this.noPreventScroll) event.preventDefault();
    },
    menuOpen() {
      if (!this.menuOpenFlg) {
        document.addEventListener("touchmove", this.noScroll, {
          passive: false,
        });
        document.addEventListener("mousewheel", this.noScroll, {
          passive: false,
        });
      } else {
        document.removeEventListener("touchmove", this.noScroll, {
          passive: false,
        });
        document.removeEventListener("mousewheel", this.noScroll, {
          passive: false,
        });
      }
      this.menuOpenFlg = !this.menuOpenFlg;
    },
    getNavHeight() {
      setTimeout(() => {
        const ulHeight = this.$refs.ul.clientHeight;

        if (ulHeight > 300 && !this.noPreventScroll) {
          this.noPreventScroll = true;
        }
      }, 500);
    },
    onKeyDown(e) {
      // MainHeader_links内のホバーで出るリンクが、タブキー操作で出るように修正中
      if (e.keyCode === 13) alert("hey");
    },
  },
};
</script>

<style scoped lang="scss">
.MainHeader {
  width: 100vw;
  position: fixed;
  z-index: 200;
  top: 0;
  background-color: #fff;

  &_inner {
    height: 100%;
    width: 100%;
    display: flex;
  }

  &_btn {
    &_apply {
      &.theme--light.v-btn {
        color: #fff;
      }
    }
  }

  &_spMenu_wrapper {
    display: none;
  }
}

@media (min-width: 1240px) {
  .MainHeader {
    height: 90px;
    padding: 0 40px;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
    &_inner {
      justify-content: space-between;
    }
    &_logo {
      display: flex;
      align-items: center;
    }
    &_right {
      display: flex;
      align-items: center;
    }
    &_links {
      display: flex;

      li {
        color: #393838;
        font-weight: bold;
        line-height: 22px;
        letter-spacing: 00.7px;
        margin: auto 20px auto 0;
        position: relative;
        padding: 20px 0;

        &:hover {
          & > a {
            opacity: 0.7;
          }
        }

        &.has-child {
          margin-right: 35px;
          &::after {
            content: url("../assets/img/common/MainHeader_expand.svg");
            position: absolute;
            top: 50%;
            right: -16px;
            transform: translateY(-45%);
          }

          // ホバー時表示のメニュー
          & > ul {
            width: 280px;
            display: block;
            position: absolute;
            left: 0;
            top: 54px;
            z-index: 400;
            background: #fff;
            visibility: hidden;
            opacity: 0;
            font-size: 16px;
            box-shadow: 0px 10px 15px -6px rgb(0 0 0 / 35%);
            transition: all 0.3s;
            padding: 10px 0;

            & li {
              cursor: pointer;
              height: auto;
              padding: 10px 0 10px 14px;
              font-weight: normal;

              & a {
                display: block;
                width: 100%;
                &:hover {
                  opacity: 0.7;
                }
              }

              &.has-title {
                font-size: 14px;
                &.title {
                  color: #9ad574;
                  font-weight: bold;
                }
              }

              & ul {
                & li {
                  margin-right: 0;
                  padding: 10px 0 0 20px;
                }
              }
            }
          }

          &:hover > ul,
          &:active > ul {
            visibility: visible;
            opacity: 1;
          }
          .has-child_inner {
            display: flex;
            flex-flow: row wrap;
            width: 560px;
            left: -246px;
            ul {
              display: inline;
              width: 280px;
            }
          }
        }
      }
    }

    &_btn {
      &_apply {
        width: 180px;
        height: 50px;
        &.v-btn.v-size--default {
          font-size: 16px;
        }
      }
    }

    &_Humberger {
      display: none;
    }
  }
}

@media (max-width: 1239px) {
  .MainHeader {
    height: 60px;
    box-shadow: 0 3px 8px 0 rgba(51, 51, 51, 0.1);
    &_inner {
      align-items: center;
      justify-content: center;
      position: relative;
    }

    &_logo {
      & img {
        width: 200px;
      }
    }

    &_right {
      display: none;
    }

    &_Humberger {
      position: absolute;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 20px;
      height: 20px;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);

      &_line {
        width: 100%;
        height: 2px;
        background-color: #393838;
        position: relative;
        transition: all 0.2s;
        &::before,
        &::after {
          position: absolute;
          content: "";
          display: block;
          width: 20px;
          height: 2px;
          background-color: #393838;
          transition: all 0.4s;
        }
        &::before {
          top: -7px;
        }
        &::after {
          bottom: -7px;
        }
      }

      &.open {
        & .MainHeader_Humberger_line {
          background-color: transparent;
          &::before {
            transform: translateY(7px) rotate(-45deg);
          }
          &::after {
            transform: translateY(-7px) rotate(45deg);
          }
        }

        & + .MainHeader_spMenu_wrapper {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    &_spMenu {
      &_wrapper {
        display: block;
        width: 100vw;
        height: calc(100vh - 60px);
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 60px;
        z-index: 5;
        pointer-events: none;
        opacity: 0;
        background-color: white;
        transition: opacity 0.4s;
      }

      height: 100%;
      padding: 20px 0 80px;
      overflow-y: auto;
      -webkit-overflow-scrolling: auto;

      & > ul {
        & > li {
          padding: 14px 20px;
          color: #393838;
          font-weight: 600;
          letter-spacing: 1.1px;

          & a {
            width: 100%;
            display: block;
          }

          & .SpMenuAccordion {
            position: relative;
            &.open {
              .SpMenuAccordion:after {
                transform: rotateX(180deg);
                margin-top: -10px;
              }
            }
            &_body {
              & ul {
                padding-top: 10px;
                & li {
                  padding: 14px 0 14px 20px;
                  font-weight: normal;
                  line-height: 1.2;

                  & a {
                    width: 100%;
                    display: block;
                  }

                  &:last-of-type {
                    padding-bottom: 10px;
                  }

                  &.has-title {
                    color: #9ad574;
                    font-weight: bold;
                    font-size: 14px;

                    & > ul {
                      padding-top: 0;
                      & > li {
                        color: #393838;
                        line-height: 1.2;
                        padding: 14px 0 0 10px;
                        & a {
                          pointer-events: auto;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      & .btn_wrapper {
        padding: 40px 0 60px;
        text-align: center;
      }
    }
  }
}
</style>
