<template>
  <main class="CX_contact common-style-1">
    <Header />
    <div>
      <h2>
        <v-btn v-show="!done" depressed @click="back()"><div></div></v-btn>お問い合わせ{{ done ? '完了' : '' }}
      </h2>
      <section class="inner">
        <div v-show="!done" class="contact_content" v-bind:class="{ create: !verify, confirm: verify }">
          <div class="contact_title">
            <template v-if="verify"
              >内容をよくお確かめの上、<br class="pc-hide" />「お問い合わせ」ボタンを押してください。</template
            >
            <template v-if="!verify"
              >以下の項目を入力し、内容をよくお確かめの上、<br
                class="pc-hide"
              />「同意して確認」ボタンを押してください。</template
            >
          </div>
          <ValidationObserver v-slot="{ invalid }">
            <form>
              <div class="contact_table_container">
                <table>
                  <tbody>
                    <tr class="double">
                      <th><span>氏名</span></th>
                      <td>
                        <ValidationProvider v-show="!verify" name="名字" v-slot="{ errors }" rules="required|max:15">
                          <v-text-field
                            v-model="data.last_name"
                            placeholder="山田"
                            class="input half_first"
                            type="text"
                            maxlength="15"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                        <div v-show="verify" class="contact_verify">{{ data.last_name }}</div>
                      </td>
                      <td>
                        <ValidationProvider v-show="!verify" name="名前" v-slot="{ errors }" rules="required|max:15">
                          <v-text-field
                            v-model="data.first_name"
                            placeholder="太郎"
                            class="input"
                            type="text"
                            maxlength="15"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                        <div v-show="verify" class="contact_verify">{{ data.first_name }}</div>
                      </td>
                    </tr>
                    <tr>
                      <th><span>会社名</span></th>
                      <td>
                        <ValidationProvider v-show="!verify" name="会社名" v-slot="{ errors }" rules="required|max:64">
                          <v-text-field
                            v-model="data.companyName"
                            placeholder="デフィデ株式会社"
                            class="input"
                            type="text"
                            maxlength="64"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                        <div v-show="verify">{{ data.companyName }}</div>
                      </td>
                    </tr>
                    <tr>
                      <th><span>部署名</span></th>
                      <td>
                        <ValidationProvider v-show="!verify" name="部署名" v-slot="{ errors }" rules="required|max:64">
                          <v-text-field
                            v-model="data.departmentName"
                            placeholder="人事部"
                            class="input half"
                            type="text"
                            :error-messages="errors"
                            maxlength="64"
                          ></v-text-field>
                        </ValidationProvider>
                        <div v-show="verify" class="contact_verify">{{ data.departmentName }}</div>
                      </td>
                    </tr>
                    <tr>
                      <th><span>電話番号</span></th>
                      <td>
                        <ValidationProvider
                          v-show="!verify"
                          name="電話番号"
                          v-slot="{ errors }"
                          rules="required|numeric|min:10|max:11"
                        >
                          <v-text-field
                            v-model="data.telephoneNumber"
                            placeholder="08012345678"
                            class="input half"
                            type="tel"
                            minlength="10"
                            maxlength="11"
                            :error-messages="errors"
                            auto-grow
                          ></v-text-field>
                        </ValidationProvider>
                        <div v-show="verify" class="contact_verify">{{ data.telephoneNumber }}</div>
                      </td>
                    </tr>
                    <tr>
                      <th><span>メールアドレス</span></th>
                      <td>
                        <ValidationProvider
                          v-show="!verify"
                          name="メールアドレス"
                          v-slot="{ errors }"
                          rules="required|email|max:100"
                        >
                          <v-text-field
                            v-model="data.emailAddress"
                            placeholder="半角英数"
                            class="input"
                            type="email"
                            required
                            maxlength="100"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                        <div v-show="verify" class="contact_verify">{{ data.emailAddress }}</div>
                      </td>
                    </tr>
                    <tr>
                      <th><span>お問い合わせ内容</span></th>
                      <td>
                        <ValidationProvider
                          v-show="!verify"
                          v-slot="{ errors }"
                          name="お問い合わせ内容"
                          rules="required|max:1000"
                        >
                          <v-textarea
                            v-model="data.content"
                            placeholder="1000文字以内"
                            rows="1"
                            row-height="28"
                            auto-grow
                            no-resize
                            required
                            maxlength="1000"
                            class="textarea"
                            :error-messages="errors"
                          ></v-textarea>
                        </ValidationProvider>
                        <div v-show="verify" class="contact_verify mb">{{ data.content }}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="contact_text" v-if="!verify">
                お問い合わせには
                <a href="https://www.defide-ix.com/privacyPolicy/" target="_blank" class="contact_link" rel="noopener"
                  >「プライバシーポリシー」</a
                ><br class="pc-hide" />の同意が必要です。
              </div>
              <div class="contact_btns">
                <v-btn depressed class="cancel" :disabled="submitting" outlined color="#080954" @click="back()">{{
                  verify ? '戻る' : 'キャンセル'
                }}</v-btn>
                <v-btn :disabled="invalid" depressed :loading="submitting" @click="next()" color="#f97c40" class="ok">{{
                  verify ? 'お問い合わせ' : '同意して確認'
                }}</v-btn>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div v-show="done" class="completion_content">
          <div class="completion_title">
            <h3>ありがとうございました。<br />お問い合わせを受け付けました。</h3>
            <p>
              <span>折り返し、担当者よりご連絡いたしますので、 <br />恐れ入りますが、しばらくお待ちください。</span>
              <br />
              <br />なお、ご入力いただいたメールアドレス宛に受付完了メールを配信しております。
              <br />完了メールが届かない場合、処理が正常に行われていない可能性があります。
              <br />大変お手数ですが、再度お問い合わせの手続きをお願い致します。
            </p>
          </div>
          <v-btn depressed :to="{ name: 'Main', params: { innerLink: true } }" rounded color="#9ad574" class="top"
            >トップページへ戻る</v-btn
          >
        </div>
      </section>
    </div>
    <Footer />
  </main>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Vue from 'vue'
import { localize, extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja.json'
import { required, numeric, min, max, email } from 'vee-validate/dist/rules'
import axios from 'axios'
extend('required', required)
extend('numeric', { ...numeric, message: '{_field_}は半角数字で入力してください' })
extend('min', min)
extend('email', email)
extend('max', max)
extend('email', {
  ...email,
  message: '正しいフォーマットで入力してください',
})
localize('ja', ja)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

export default {
  name: 'Contact',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      data: {
        last_name: '',
        first_name: '',
        fullname: '',
        companyName: '',
        departmentName: '',
        telephoneNumber: '',
        emailAddress: '',
        content: '',
      },
      verify: false,
      submitting: false,
      done: false,
    }
  },
  mounted() {
    document.title = 'お問い合わせ | JOB Scope'
  },
  methods: {
    back() {
      if (this.verify) {
        this.verify = false
        this.$router.replace({ params: { id: 1 } })
        document.title = 'お問い合わせ | JOB Scope'
      } else {
        this.$router.push({ name: 'Main' })
      }
    },
    next() {
      if (!this.verify) {
        this.verify = true
        this.$router.replace({ params: { id: 2 } })
        document.title = 'お問い合わせ確認 | JOB Scope'
      } else {
        this.submitting = true
        const self = this
        let submitData = JSON.parse(JSON.stringify(self.data))
        submitData.fullname = self.data.last_name + ' ' + self.data.first_name
        delete submitData.last_name
        delete submitData.first_name
        let base
        switch (location.hostname) {
          case 'hrscape.jp': //本番環境
          case 'www.hrscape.jp':
          case 'jobscope.ai':
          case 'www.jobscope.ai':
          case 'd2pbie4k5e8wx6.cloudfront.net':
            base = 'https://jobscope.ai/api/lp/inquiry'
            break
          case 'd1g1s3y0z05pku.cloudfront.net': //DEV環境
          case 'dev.jobscope.ai':
            base = 'https://dev.jobscope.ai/api/lp/inquiry'
            break
          case 'd1a6pp170hn3cr.cloudfront.net': //STG環境
          case 'stg.jobscope.ai':
            base = 'https://stg.jobscope.ai/api/lp/inquiry'
            break
          case 'jobscope.online':
            base = 'https://console.jobscope.online/api/lp/inquiry' //DEMO環境
            break
          default:
            base = 'https://dev.jobscope.ai/api/lp/inquiry' //ローカルもDEV環境
            break
        }
        const instance = axios.create({
          baseURL: base,
          timeout: 30000,
        })
        instance
          .post('', submitData)
          .then(function () {
            self.done = true
            self.$router.replace({ params: { id: 3 } })
            document.title = 'お問い合わせ完了 | JOB Scope'
            self.submitting = false
          })
          .catch(function (res) {
            alert(`お問い合わせ情報の送信に失敗しました。お手数ですが時間を置いて再度お試しください。\n${res}`)
            self.submitting = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.CX_contact {
  h2 {
    font-family: 'Noto Sans JP', sans-serif;
  }

  .input,
  .textarea {
    color: #080954;
    caret-color: #080954;
    font-weight: 500;
    &::placeholder {
      color: #ccc;
    }
  }
  .cancel {
    width: 170px;
    height: 36px;
    line-height: 36px;
    border-width: 2px;
    &.v-btn--disabled {
      border: none !important;
    }
  }
  .ok {
    color: white;
    width: 200px;
    height: 50px;
    line-height: 50px;
    &.v-btn--disabled {
      background-color: #ccc !important;
    }
  }
  .completion_content {
    .top {
      width: 200px;
      height: 40px;
      border-radius: 20px;
      display: block;
      margin: 0 auto;
      font-size: 16px;
      text-align: center;
      color: white;
    }
  }
}

@media (max-width: 767px) {
  .CX_contact {
    .sp-hide {
      display: none;
    }
    .inner {
      .contact_content {
        margin: 0 auto;
      }

      .contact_title {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 28px;
        text-align: center;
        white-space: nowrap;
        @media (min-width: 600px) {
          br {
            display: none;
          }
        }
      }

      table {
        border: 0;
        width: 100%;
        tr {
          display: block;
        }
        th {
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          width: 100%;
          line-height: 21px;
          color: #080954;
          span {
            display: inline-block;
            position: relative;
          }
        }
        th,
        td {
          display: block;
        }
        td {
          position: relative;
        }
      }
      .input,
      .textarea {
        padding-top: 4px;
        line-height: 24px;
        width: 100%;
      }
      .input {
        margin-bottom: 14px;
        height: 100%;
        margin-top: 0;
      }

      .contact_text {
        font-size: 14px;
        margin-top: 35px;
        line-height: 18px;
        text-align: center;
        @media (min-width: 600px) {
          br {
            display: none;
          }
        }
      }
      .contact_btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 50px;
        margin: 30px auto 0;
        > .v-btn {
          font-size: 16px;
          text-align: center;
          font-weight: 500;
          letter-spacing: 0.8px;
        }
        .cancel {
          order: 2;
        }
        .ok {
          margin-bottom: 20px;
          order: 1;
        }
      }
    }
  }
  .create {
    th {
      span {
        &::after {
          top: 1px;
          right: -26px;
        }
      }
    }
  }
  .confirm {
    table {
      max-width: 100%;
      table-layout: fixed;
    }
    td {
      line-height: 21px;
      padding-bottom: 28px;
    }
    .double td {
      display: inline-block !important;
      &:first-of-type {
        padding-bottom: 7px;
        margin-right: 20px;
      }
    }
    th {
      padding-bottom: 10px !important;
    }
    .contact_btns {
      margin-top: 20px !important;
    }
    .mb {
      margin-bottom: 20px;
      white-space: pre-line;
    }
  }
  .completion_content {
    h3 {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 28px;
      text-align: center;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 34px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@media (min-width: 768px) {
  .CX_contact {
    .pc-hide {
      display: none;
    }
    .inner {
      .contact_content {
        margin: 0 auto;
        .contact_table_container {
          width: 100%;
          max-width: 648px;
        }
        table {
          width: 100%;
        }
      }

      .contact_title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 45px;
        text-align: center;
      }

      form {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      table {
        border: 0;
        tr {
          display: flex;
          &:not(:last-of-type) {
            margin-bottom: 36px;
          }

          td {
            flex-grow: 1;
            .half_first {
              margin-right: 10px;
            }
          }
        }
        th {
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          width: 200px;
          color: #080954;
          span {
            display: block;
            position: relative;
            line-height: 32px;
            &::after {
              top: 2px;
              right: 12px;
            }
          }
        }
      }

      .contact_text {
        font-size: 14px;
        margin-top: 66px;
        font-weight: 500;
      }
      .contact_btns {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px auto 0;
        button {
          font-size: 16px;
        }
        .cancel {
          margin-right: 30px;
        }
      }
    }
  }

  .confirm {
    tr {
      margin-bottom: 42px !important;
    }
    th {
      line-height: 24px;
    }
    td {
      width: 450px;
      line-height: 24px;
      word-break: break-all;
      white-space: pre-line;
    }
    .double td {
      width: 250px;
    }
    .contact_btns {
      margin-top: 44px !important;
    }
  }

  .completion_content {
    text-align: center;
    h3 {
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 46px;
      font-weight: bold;
    }
    p {
      line-height: 22px;
      margin-bottom: 48px;
      font-weight: 500;
    }
    .top {
      width: 200px;
      height: 36px;
      line-height: 36px;
      border-radius: 6px;
      display: block;
      margin: 0 auto 13px;
      font-size: 18px;
    }
  }
}
</style>

<style lang="scss">
.CX_contact {
  input,
  textarea {
    color: #393838 !important;
  }
  .v-btn__content {
    font-weight: bold;
  }
  .v-btn--disabled .v-btn__content {
    color: white;
  }
  .create {
    input {
      font-weight: 500;
    }
    .v-input {
      margin-top: 0;
      padding-top: 0;
    }
    .v-messages__message {
      color: #ff5252 !important;
    }
    .contact_link {
      color: #ff6600;
    }
    th {
      span {
        &::after {
          content: url('../assets/img/common/ico_required.svg');
          position: absolute;
          display: block;
          width: 18px;
          height: 18px;
        }
      }
    }
    .error--text {
      color: #ff5252 !important;
      caret-color: #ff5252 !important;
    }
  }
  .completion_content {
    .v-btn__content {
      line-height: 40px;
    }
  }
}
@media (max-width: 767px) {
  .CX_contact {
    .create {
      input {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 4px;
        margin-bottom: 6px !important;
      }
      .double td {
        &:first-of-type .input {
          margin-bottom: 0;
        }
        &:last-of-type .input {
          padding-top: 0;
        }
      }
      textarea {
        line-height: 24px !important;
        padding-bottom: 8px;
      }
    }
  }
}
@media (min-width: 768px) {
  .CX_contact {
    .contact {
      .v-input {
        margin-top: 0;
        padding: 0;
      }
      input {
        padding-top: 0 !important;
        padding-bottom: 8px !important;
        height: 28px;
      }
    }
  }
}
</style>
