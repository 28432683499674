<template>
  <main class="CX_terms common-style-1">
    <Header />
    <div>
      <h2>
        <v-btn depressed link to="/"><div></div></v-btn>利用規約
      </h2>
      <section class="inner">
        <div class="top">
          <p>
            JOB
            Scopeの利用企業（以下、「甲」という。）は、次の事項において、デフィデ株式会社(以下、「乙」という。)が使用を許諾するクラウドサービス（以下、本サービス）に関する利用規約、および
            <a
              href="https://jobscope.ai/privacy"
              target="_blank"
              >「プライバシーポリシー」</a
            >
            に同意の上、利用する。
          </p>
        </div>
        <div class="first">
          <h3>第１条（本サービスの使用許諾および利用条件）</h3>
          <p>
            乙は、甲に対し、本サービスを使用する権利を許諾する。
            <br />ただし、乙は甲への事前の了承を得ることなく本サービスの仕様を変更できるものとする。
          </p>
        </div>
        <div>
          <h3>第２条（利用者IDおよびパスワードの管理）</h3>
          <p>
            甲は、自己の責任において、本サービスにおいて使用するIDおよびパスワードを管理するものとする。
            <br />また甲は、いかなる場合にも、IDおよびパスワードを第三者に譲渡または貸与することはできないものとする。
            <br />乙は、IDとパスワードの組み合わせが登録情報と一致してログインされた場合には、その利用者IDを登録している利用者自身による利用とみなす。
          </p>
        </div>
        <div>
          <h3>第３条（費用）</h3>
          <p>本サービスを利用する場合にかかる通信費および機器の利用にかかる一切の費用は、甲の負担となる。</p>
        </div>
        <div>
          <h3>第４条（有償利用）</h3>
          <p>
            利用申込み手続きを実行後、利用アカウントを発行した日を利用開始日とし、同日以降、甲が本サービスを利用しない場合であっても利用料金が発生するものとする。
          </p>
        </div>
        <div>
          <h3>第５条（利用料金及び支払）</h3>
          <ul>
            <li>
              1. 初期費用に関して
              <ul class="list">
                <li>甲は乙が提供するサービスの利用開始に際して、設定された初期費用を支払うものとする。</li>
                <li>
                  乙は利用開始月の月末までに甲に請求書の送付を行ない、甲は翌月末日までに乙が指定する銀行口座への振込を行なうものとする。尚、振込手数料は甲の負担とする。
                </li>
              </ul>
            </li>
            <li>
              2. 月額サブスクリプション費用に関して
              <ul class="list">
                <li>
                  甲は乙が提供するサービスの利用に際して、設定されたサブスクリプション費用を支払うものとする。
                </li>
                <li>
                  毎月の請求は乙が算出した金額を該当月の翌月5営業日までに請求書の送付を行ない、甲は翌月末日までに乙が指定する銀行口座への振込を行なうものとする。尚、振込手数料は甲の負担とする。
                </li>
              </ul>
            </li>
            <li>
              3. オプション費用に関して
              <ul class="list">
                <li>
                  甲がコンサルタントによる導入時人事制度設計や人事コンサルタントによる年間アドバイザリーサービスを利用する場合には、別途契約の上、サービス利用を行なうものとする。
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <h3>第６条（解約）</h3>
          <ul>
            <li>
              1. 利用サービスの解約に関して
              <ul class="list">
                <li>甲が解約の申し出を行なわない限り、サービス利用契約は自動的に継続更新とする。</li>
                <li>
                  甲がサービスの解約を希望する場合、解約申請は終了月の前月20日までに行なうものとする。
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <h3>第７条（著作権等の知的財産権）</h3>
          <p>本サービスに関するすべての著作権等の知的財産権は、乙に帰属する。</p>
        </div>
        <div>
          <h3>第８条（個人情報の取扱い）</h3>
          <p>
            乙は、法令に定める場合を除き、事前に甲の同意を得ることなく、契約企業及び登録者情報に含まれている個人情報を第三者へ開示、提供することはない。
          </p>
        </div>
        <div>
          <h3>第９条（データの利用）</h3>
          <p>
            本サービスを利用することで得られる情報（ハードウェア、オペレーティングシステム、アプリケーションソフトウェアを含むが、これらに限定するものではない）は甲の個人を特定できない情報として収集、保存、加工し、統計値として当社の製品、サービスおよびマーケティング活動に利用する場合がある。
          </p>
        </div>
        <div>
          <h3>第１０条（利用禁止）</h3>
          <p>
            甲は、本サービスを利用する際に、以下に該当する行為およびその恐れのある行為を行なってはならない。以下に該当する行為があった場合には、即時利用停止とする。
          </p>
          <ul class="list ml">
            <li>本サービス及び本サービスで利用しうる情報の改ざん</li>
            <li>当社および第三者の知的財産権を侵害し、または侵害する恐れのある行為</li>
            <li>有害なコンピュータプログラム等の送信および書き込み</li>
            <li>プライバシーを侵害し、または侵害する恐れのある行為</li>
            <li>本サービスを転売するなど、営利を目的とした行為</li>
            <li>本サービスの運用・利用を妨げる行為</li>
            <li>不正なアクセス行為</li>
            <li>端末製造者が認めない方法により変更、改造を行った端末において本サービスを利用する行為</li>
            <li>法令に違反する行為または違反する恐れのある行為</li>
            <li>その他、当社が不適切と判断する行為</li>
          </ul>
        </div>
        <div>
          <h3>第１１条（非保証）</h3>
          <p>
            乙及び本サービスの提供に関与している企業は、本サービス及び本サービスに付随するサービスに対する次の各号をはじめとする一切の保証行為を行っていない。
          </p>
          <ul class="list ml">
            <li>本サービス利用に起因して利用者等の端末機器に不具合や障害が生じないこと</li>
            <li>本サービスの正確性及び完全性</li>
            <li>本サービスが永続すること</li>
            <li>本サービスの利用が利用者に必要な事項を満たすこと</li>
            <li>本サービスの利用に中断またはエラーが発生しないこと</li>
          </ul>
        </div>
        <div>
          <h3>第１２条（利用許諾の解除）</h3>
          <p>
            本利用契約の禁止事項に抵触した場合、乙は甲へ一方的に利用契約を解除できるものとする。
          </p>
        </div>
        <div>
          <h3>第１３条（安全管理措置について）</h3>
          <p>本サービスにおいて個人情報を取り扱うに際して、乙では以下の安全管理措置を行なうものとする。</p>
          <ul class="list ml">
            <li>
              個人データを取り扱うことのできる機器及び当該機器を取り扱う従業者を明確化し、個人データへの不要なアクセスを防止する
            </li>
            <li>
              アカウント制御により、個人情報データベース等を取り扱う情報システムを使用する従業者を識別・認証する
            </li>
            <li>
              セキュリティ対策ソフトウェア等を導入及び最新化により外部からの不正アクセス又は不正ソフトウェアから保護する
            </li>
            <li>
              個人データの含まれるファイルを送信する場合には、通信の経路及び内容を暗号化し、当該ファイルへのパスワードを設定する
            </li>
          </ul>
        </div>
        <div>
          <h3>第１４条（免責事項について）</h3>
          <ul>
            <li>
              1. 本サービスには、不具合が存在する可能性があり、稼働について、いかなる保証もするものではない。
              <br />乙は、いかなる理由又は原因によっても、本サービスの利用に関して甲のデータが喪失されないことを保証しない。
              <br />また、本サービス利用機器におけるデータ及び個人情報の取り扱いに関しては、利用者自身の責任となり、当該情報については一切の賠償責任を負わないものとする。
            </li>
            <li>
              2.
              甲が本サービスの利用にあたり、生じる行為による身体の負傷、財産の損害、逸失利益、代替の商品またはサービスの費用、データの喪失、信用の喪失、業務の停止、利用機器の故障または不具合、その他のいかなる直接的または間接的な損害について一切の賠償責任を負わないものとする。
            </li>
            <li>
              3.
              各種分析結果に掲載された情報を利用することで生じるいかなる損害（直接的、間接的を問わず）についても、乙は一切の責任を負うものではない。
            </li>
          </ul>
        </div>
        <div>
          <h3>第１５条（解除）</h3>
          <ul>
            <li>
              1.
              甲又は乙は、相手方に以下の事由が生じた場合には、何ら事前の通知催告を要することなく、直ちに本契約を解除することができる。
              <ul class="ml no">
                <li>（１）自己振出の手形又は小切手が不渡処分となったとき</li>
                <li>（２）差押、仮差押、仮処分、又は競売の申立があったとき</li>
                <li>
                  （３）破産、会社整理、会社更生、民事再生の手続開始の申立等を自ら行ったとき、又は申し立てられたとき
                </li>
                <li>（４）解散又は営業の全部若しくは重要な一部を第三者に譲渡したとき</li>
                <li>
                  （５）本契約の条項に違反し、相手方からの書面による催告を受領した後10日以内にこれを行わなかった場合
                </li>
                <li>（６）その他、前各号に準ずるような契約を継続し難い重大な事由が発生した場合</li>
              </ul>
            </li>
            <li>
              2.
              前項により、甲又は乙が本契約の全部又は一部を解除した場合でも、甲又は乙は、相手方に対してその被った損害を賠償することを妨げない。
            </li>
          </ul>
        </div>
        <div>
          <h3>第１６条（損害賠償）</h3>
          <p>
            甲及び乙は、本契約及び個別契約の履行に関し、相手方の責めに帰すべき事由により損害を被った場合、相手方に対して、損害賠償を請求することができる。
            <br />但し、甲は、乙の制作したウェブサイト、システム及びアプリケーションを甲が管理運営するにあたり、甲に生じた損失、費用、その他の損害については、一切免責するものとする。
          </p>
        </div>
        <div>
          <h3>第１７条（合意管轄裁判所）</h3>
          <p>
            本利用規約の解釈および有効性は、日本国法に従って判断されるものとします。
            <br />本サービスに関し利用者と当社との間で紛争が生じた場合の準拠法はこれを日本法とします。
            <br />利用者と当社は、本利用規約に関するいかなる紛争のための専属的管轄裁判所は東京簡易裁判所あるいは東京地方裁判所とします。
          </p>
        </div>
        <div>
          <h3>第１８条（協議）</h3>
          <p>
            本契約に定めのない事項その他本契約の解釈に疑義を生じた場合には、甲乙誠実に協議を行い、円満に解決するものとする。
          </p>
        </div>
        <div class="bottom">
          <h3>付則</h3>
          <p>本規約は令和3年11月18日から実施します。</p>
        </div>
      </section>
    </div>
    <Footer />
  </main>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
export default {
  name: 'Terms',
  components: {
    Header,
    Footer,
  },
  mounted() {
    document.title = '利用規約 | JOB Scope'
  },
}
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .sp-hide {
    display: none;
  }
}
@media (min-width: 768px) {
  .pc-hide {
    display: none;
  }
}
</style>
